import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost11-img1.png";
import ShareButtons from "../components/ShareButtons";

const BlogContent11 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/fitness-as-a-lifestyle"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <>
            <Helmet>
                <title>Fitness as a Lifestyle with Taran Singh</title>

                <meta
                    name="description"
                    content="We had a conversation with Taran Singh, a personal trainer dedicated to inspiring his clients to focus on changing their lifestyles instead of focusing soley on their physiques. We dive into all things personal training, nutrition, and fitness. We promise you this is an article you don’t want to miss!"
                />
            </Helmet>

            <h2 ref={elementRef}>Fitness as a Lifestyle with Taran Singh</h2>

            <div className="authorContainer">
                <h4>March 15th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage}
                alt="Taran Singh, aka @thevintagesingh on Instagram."
            />

            <p>
                Meet Taran Singh, aka{" "}
                <a href="https://instagram.com/thevintagesingh?igshid=YmMyMTA2M2Y=">
                    @thevintagesingh
                </a>{" "}
                on Instagram. Taran is a certified personal trainer and
                nutrition specialist. Taran stood out to us as a trainer whose
                passion feels palpable. Over the weekend Taran took some time to
                talk to our team at PLTFRM about his beliefs as a personal
                trainer, and how he grew his Instagram following.
            </p>

            <p className="blogQuoteText">
                <span className="tab"></span>
                “I believe if you can help your clients feel the possible
                benefits in 3 weeks of working together, they will be driven to
                keep going.”
            </p>
            <p>
                Taran shared how his journey in fitness started with his desire
                to finally start taking care of his own body. This was a process
                that took him about 3-4 years. “Back then being fit was not
                ‘trending’ so it took me time to learn. Eventually, I decided to
                get certified when I lived in India. Even then, I never planned
                to make personal training a profession for myself.” Taran talked
                about how he did another certification when he moved to Canada
                after which he started taking on just one client at a time. This
                was a way for him to make some money to use for his expenses.
                Slowly, it started growing and now Taran is a full-time personal
                trainer for the last 3 years.
            </p>
            <p>
                Taran talked to our team about how he believes fitness is
                attainable when people view it as a lifestyle instead of
                focusing on an end goal. “I don’t want my clients waking up in
                the morning and thinking oh on I need to work out today. This
                won’t work long term. This is why I focus on making being fit a
                lifestyle.” Taran shared how the way he does this for himself is
                by making sure working out doesn’t only mean going to the gym.
                He is someone who enjoys sports so he incorporates sports into
                his week. “Incorporating weekly basketball and badminton
                sessions in my schedule helps me stay active and stay
                motivated.”
            </p>

            <p className="blogQuoteText">
                <span className="tab"></span>
                “I once had a trainer that used literally tell me to starve
                myself. I forced myself to such an extreme that I stopped going
                to the gym for 4-5 months. I never want to do that to my
                clients.”
            </p>
            <p>
                Through our conversation, we heard the way Taran viewed his
                clients and dedicated himself to their goals. It was very easy
                for us to understand why clients wanted to work with him. Taran
                shared with us how when a client comes to him, he asks them for
                3 weeks of dedication and he will help them fall in love with
                fitness. “I believe if you can help your clients feel the
                possible benefits in 3 weeks of working together, they will be
                driven to keep going.” Taran talked about how clients always
                come saying they trust him as a trainer to get them to their
                goals. Taran believes it’s his responsibility to take that trust
                and actually show them what is possible within the first 3
                weeks. “I understand that if people don’t see or feel changes
                within the first 3 weeks it can become easy for people to fall
                away.”
            </p>
            <p>
                Taran went on to share with us that the reason he is able to
                connect with his clients is that he himself has been where they
                are. “I once had a trainer that used literally tell me to starve
                myself. I forced myself to such an extreme that I stopped going
                to the gym for 4-5 months. I never want to do that to my
                clients.” Taran now uses his knowledge in nutrition and fitness
                to help set his clients up for attainable success.
            </p>

            <p className="blogQuoteText">
                <span className="tab"></span>
                “When I transitioned from working in a restaurant to going into
                my fitness business full time there was a period when my income
                dropped.”
            </p>

            <p>
                As our conversation with Taran continued, we asked him for his
                advice would be for someone who didn’t love the work they did.
                Taran shared how his advice has always been and will always be
                to start building something on the side. If you want to build
                something you have to dedicate time to it. If you aren’t willing
                to do that then you have to accept what you have. “When I
                transitioned from working in a restaurant to going into my
                fitness business full time there was a period when my income
                dropped. This is just how it works.” Taran talked about how you
                have to understand this and be willing to grow through this
                journey.{" "}
            </p>

            <p>
                Today Taran has a big Instagram and TikTok following. Getting
                here didn’t happen overnight and is not something he takes for
                granted. Taran shared how the way he grew these communities was
                by focusing on producing content that his audience wanted to see
                and that would actually help them. “It’s tough creating content
                every day. My advice is that when inspiration strikes drop
                whatever you are doing and go create the content.” Taran talked
                about how you never know what content will do well which is why
                it’s important to create whenever you feel inspired to create
                something.
            </p>

            <p>
                Our conversation with Taran left us feeling inspired and driven
                which is how we are sure his clients feel as well. We are beyond
                excited to keep following his journey and celebrating his
                growth. You can connect with Taran on Instagram{" "}
                <a href="https://instagram.com/thevintagesingh?igshid=YmMyMTA2M2Y=">
                    @myvintagesingh.
                </a>
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/the-art-of-communication">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/the-role-of-social-media">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent11;
