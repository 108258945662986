import React from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
} from "react-share";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedin } from '@fortawesome/free-brands-svg-icons'

function ShareButtons(props) {
    
    const shareUrl = `https://pltfrm.fit${props.url}/?1`
  return (
    <>
        
      <section className='shareContainer'>
        <h4>Share the post!</h4>
        <div>
          <FacebookShareButton className="shareButton" url={shareUrl}>
              <FontAwesomeIcon icon={faFacebookF} className="fa-fw shareIcon" />
              <span className="sr-only">Facebook Icon</span>
          </FacebookShareButton>

          <LinkedinShareButton className="shareButton" url={shareUrl} >
              <FontAwesomeIcon icon={faLinkedin} className="fa-fw shareIcon" />
              <span className="sr-only">LinkedIn Icon</span>
          </LinkedinShareButton>

        </div>
      </section>
    </>
  );
}

export default ShareButtons