import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogThumbnails/blogPost4-thumbnail.png";

const BlogContent4 = () => {

    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/building-confidence-and-resilience-in-a-competitive-world"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <>
            <Helmet>
                <title>
                    Building Confidence and Resilience in a Competitive World
                </title>

                <meta
                    name="description"
                    content="A conversation with @fitadriene on how she started her competitive bodybuilding career."
                />
            </Helmet>

            <h2 ref={elementRef}>
                Building Confidence and Resilience in a Competitive World
            </h2>

            <div className="authorContainer">
                <h4>January 16th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg adrienneImg"
                src={blogImage}
                alt="Competitive bodybuilder Adrienne leaning against wall, smiling.  PLTFRM logo with text `a conversation with @fitadrienne`"
            />

            <p>
                Adrienne Na of{" "}
                <a href="https://www.instagram.com/fitadrienne/">
                    @fitadrienne
                </a>{" "}
                on Instagram had a conversation with our team to share her
                fitness journey in competitive bodybuilding and as a trainer.
                Adrienne thinks of fitness as a huge passion and hobby in her
                life that compliments her career as a board-certified
                Naturopathic Doctor. Adrienne has also been a personal trainer
                for several years, and she still takes on 1:1 clients every once
                in a while.
            </p>

            <p className="blogQuoteText">
                <span className="tab"></span>
                “When I share my history with bodybuilding it might inspire them
                to workout more or eat cleaner, but if it doesn’t fit their
                lifestyle then I would never recommend it.”
            </p>
            <p>
                Adrienne believes that part of her responsibility as a Doctor is
                to be an example of what she preaches to her clients. Even
                though bodybuilding is a huge passion in her life she doesn’t
                preach it as a method to all her clients. “When I share my
                history with bodybuilding it might inspire them to workout more
                or eat cleaner, but if it doesn’t fit their lifestyle then I
                would never recommend it. Bodybuilding is not for everyone and
                it is not the best way to sustain a healthy lifestyle. This is
                why I try to keep my Naturopathic practice separate from my work
                in fitness. My social media is focused on inspiring and sharing
                my fitness journey.”
            </p>
            <p>
                We then took a walk down memory lane as Adrienne shared how she
                started her fitness and bodybuilding journey. After taking her
                final exams for her naturopathic degree she had a 3-month
                waiting period before she got her results of the exams… Adrienne
                decided to use these 3 months to work on herself. This led her
                to hire a personal trainer for the very first time. Her trainer
                began working with her and eventually encouraged her to start
                competing. “I entered my first show after 3 months. It was a
                small, local show. I ended up placing and have continued
                competing since then. I entered my first show in 2013 so it’s
                been 10 years of competitions now."
            </p>
            <p>
                Adrienne went on to share that she used not to feel comfortable
                with her body and felt insecure. She shared how she used to be
                very skinny and would often get asked if she had an eating
                disorder. “I came to Canada at the age of 17, and English was
                not my first language,” Adrienne explained that the silver
                lining of not speaking a language fluently was that she learned
                how to be an active listener. Fitness training is what
                eventually helped her build strength in her body and resilience
                in her mind. Adrienne shared how the body frame she has today
                has been 10 years in the making, not something she built
                overnight.
            </p>
            <p className="blogQuoteText">
                <span className="tab"></span>
                “There were not a lot of Korean women represented in
                bodybuilding when I started. I was one of the few faces.”
            </p>
            <p>
                Adrienne’s confidence grew over time. She shared how exciting it
                was when she started to be approached by Korean magazines for
                interviews and campaigns. “There were not a lot of Korean women
                represented in bodybuilding when I started. I was one of the few
                faces.” This helped Adrienne start to understand that maybe she
                could use her story to inspire and motivate others. Her
                Instagram channel began as a diary for herself. Even today,
                Adrienne loves that she can scroll down and see her progress and
                journey over time.
            </p>
            <p>
                We had so much fun speaking with Adrienne! She was inspiring and
                so authentic and open in sharing her story. If you’d like to
                connect with her you can find her on Instagram{" "}
                <a href="https://www.instagram.com/fitadrienne/">
                    @fitadrienne.
                </a>{" "}
                Rumor also has it that she has a website dedicated to fitness
                coming out soon that you should probably keep an eye out for!
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/4-tips-to-start-a-fitness-business">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/key-takeaways-from-2022-canfitpro-survey">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent4;
