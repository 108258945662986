import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost9-img1.png";
import ShareButtons from "../components/ShareButtons";

const BlogContent9 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/movement-and-longevity"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <>
            <Helmet>
                <title>
                    A Conversation about Movement and Longevity with Joanne Lam
                </title>

                <meta
                    name="description"
                    content="An in-depth conversation with Joanne Lam about team sports, movement, and longevity. If you are someone wondering if you are ‘too old’ to play a sport you love, this blog post is for you!"
                />
            </Helmet>

            <h2 ref={elementRef}>
                A Conversation about Movement and Longevity with Joanne Lam
            </h2>

            <div className="authorContainer">
                <h4>February 21st, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage}
                alt="Personal trainer Joanne Lam at the gym"
            />

            <p>
                This week we would like to introduce you to Joanne Lam,{" "}
                <a href="https://instagram.com/joannelamfitness?igshid=YmMyMTA2M2Y=">
                    @Joannelamfitness
                </a>{" "}
                on Instagram. Joanne began her journey as a personal trainer in
                2018. “I’ve spent my whole life moving from place to place and
                was in a transition period in 2017 when I moved from the United
                States to Toronto. When I came here I wasn’t sure what I was
                going to do. I had always been interested in fitness and wanted
                to learn more for my own training. I decided to get my personal
                trainer license and now here we are.”
            </p>

            <p className="blogQuoteText">
                <span className="tab"></span>
                “I believe there is a time and season to push ourselves but I
                also believe that there is a need for us as a culture to learn
                to slow down and commit to a program.”
            </p>
            <p>
                Over the course of our conversation, Joanne shared how her
                background in athletics is what drove her interest in personal
                training. She worked at a gym in Toronto and when the
                opportunity to be a trainer presented itself she grabbed it.
                Today, Joanne focuses on bodyweight and kettlebell work with
                clients. Depending on who the client is, Joanne likes to focus
                on strength training and training for longevity.
            </p>
            <p>
                As we got into a conversation about longevity in the fitness
                world, Joanne shared her personal experience as a professional
                athlete. Joanne grew up playing basketball, gymnastics, and
                rugby. She traveled around Asia for a while in school playing
                basketball. She also played rugby for the Cambodian Women's
                National Team for a year. When she went to college in the States
                she joined her university Quidditch team, aka Quadball. “I
                played in university and then stopped for a couple of years,
                thinking to myself it’s time to retire, I’m getting old.” Joanne
                went on to talk about how she eventually started playing
                Quadball again for a team here in Toronto about 2 years ago. “I
                think sports teach us how to control our body and we are never
                too old to play sports.” Joanne shared how she believes a lot of
                the big lessons she learned in life came from playing sports.
            </p>

            <p className="blogQuoteText">
                <span className="tab"></span>
                “We often joke about not having as much stamina as the younger
                players but we also know that when we go on a pitch that there
                are so many more aspects to a sport and movement that sometimes
                being older and wiser can work to your benefit.”
            </p>
            <p>
                Joanne spoke about the season in life, post-university when she
                felt she might be ‘too old’ for sports as a season when she
                wasn’t as physically active as she is today. Quadball is a
                physically demanding sport. It’s usually a coed team and is a
                full-contact sport. “I used to feel demotivated because I’d be
                pretty sore for days on end.” She went on to share how when she
                moved to Toronto and started training more and gaining more
                knowledge about fitness and the body in general she realized she
                could keep playing if she changed the intensity slightly and was
                very consistent with training. Joanne shared that she considers
                herself to be part of the first generation of Quadball players
                and most of her team in Toronto is in the same boat. “We often
                joke about not having as much stamina as the younger plays but
                we also know that when we go on a pitch that there are so many
                more aspects to a sport and movement than just stamina.
                Sometimes being older and wiser can work to your benefit.”
            </p>
            <p>
                We asked Joanne about what she believes a personal trainer can
                bring to clients. She began sharing her view on the different
                ways we can work out and we dove into a conversation about
                pushing ourselves versus slowing down. “I believe there is a
                time and season to push ourselves but I also believe that there
                is a need for us as a culture to learn to slow down and commit
                to a program.” This is where Joanne believes a personal trainer
                can be so useful as a guide, coach, and accountability partner.
            </p>

            <p>
                Toward the end of our time together Joanne shared how movement
                is something we are never too old for. “I like to ask my clients
                where they see themselves when they are 60 or 70 years old. Do
                they want to get down on the floor with their grandkids? If they
                do, my advice is to start now and to be consistent.{" "}
            </p>

            <p>
                We absolutely loved our conversation with Joanne. We are excited
                to see her business continue to grow and expand. Joanne trains
                clients in the downtown Toronto region. If you’d like to connect
                with her you can find her on Instagram{" "}
                <a href="https://instagram.com/joannelamfitness?igshid=YmMyMTA2M2Y=">
                    @joannelamfitness.
                </a>
            </p>
            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/the-role-of-social-media">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/do-this-to-grow-your-business">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent9;
