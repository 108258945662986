import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import quoteImg from "../../assets/trainerAssests/quotes.png";
import { useLocation } from "react-router-dom";

function Daniela() {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/ourtrainers/trainer/Daniela") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
    return (
        <>
            <header className="trainerHeader danielaHeader" ref={elementRef}>
                <div className="wrapper">
                    <div className="trainerHeaderText">
                        <h1>Meet Daniela</h1>
                        <p>A PLTFRMPRO Trainer</p>
                        <button className="trainerContactButton">
                            <Link to="/workoutform">Contact Daniela</Link>
                        </button>
                    </div>
                </div>
            </header>

            <Helmet>
                <title>
                    Find a Personal Trainer Near Me | Daniela | PLTFRM
                </title>

                <meta
                    name="description"
                    content="Daniela is a downtown Toronto fitness trainer focused on body liberation, kettlebell training, Zumba, and strength building. She's here to help you get healthy."
                />
            </Helmet>

            <section className="getToKnow">
                <div className="wrapper">
                    <h2>Get to Know Daniela...</h2>
                    <div className="trainerBio">
                        <img
                            className="trainerQuotes"
                            src={quoteImg}
                            alt="quotes"
                        />
                        <div className="bioText">
                            <p>
                                I’m a body-neutral advocate, anti-diet champion,
                                and HAES*-focused fitness coach in a mid-size
                                fat body. For too long, society made me believe
                                that because of my size, I didn’t belong at the
                                gym or in the fitness industry. During the long
                                journey of self-acceptance and gaining a neutral
                                approach to my body, I learned to say “EFF
                                THAT!” to what people thought of the size of my
                                body or what they believed I was capable of. Now
                                I work full-time to inspire others to take up
                                space in the fitness world. Let’s celebrate your
                                body through movement and explore what staying
                                healthy, strong, and finding joy means to you!
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="qualifications">
                <div className="wrapper">
                    <h2>Qualifications</h2>
                    <p>ACE</p>
                    <p>Kettlebells</p>
                    <p>Zumba</p>
                    <p>SITA (Size-Inclusive Training Academy)</p>
                </div>
            </section>
            <section className="quotes">
                <div className="wrapper">
                    <div className="quoteContainer singleQuote">
                        <h3>#1 Training Advice for People</h3>
                        <p>
                            “1. Start with something you enjoy and 2. do
                            something that you're good at to help build
                            confidence”
                        </p>
                    </div>
                </div>
            </section>
            <section className="trainerSocials">
                <div className="wrapper">
                    <h2 className="h2FormLink">
                        <Link to="/workoutform">Contact Daniela</Link>
                    </h2>
                    <div className="trainerSocialsContainer">
                        <a
                            href="https://www.instagram.com/danielafitplus/"
                            className="iconCircle"
                        >
                            <FontAwesomeIcon
                                icon={faInstagram}
                                className="fa-fw instagramIcon"
                            />
                            <span className="sr-only">Instagram Icon</span>
                        </a>
                        <a
                            href="http://www.danielafitplus.com"
                            className="iconCircle"
                        >
                            <FontAwesomeIcon icon={faGlobe} className="fa-fw" />
                            <span className="sr-only">Globe Icon</span>
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Daniela;
