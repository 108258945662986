import React from 'react'
import workbookImg from "../assets/forFitnessPage/workbook.png";
import contentGuideImg from "../assets/forFitnessPage/pltfrmContentGuideImg.jpeg";
import desk2 from "../assets/forFitnessPage/desk2.png";

import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

function Resources() {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/forfitnessprofessionals/resources") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
            
        },
        [location]
    );
  return (
      <section className="resourceSection" ref={elementRef}>
          <div className="wrapper">
              <h2 className="fitnessSectionTitle">
                  Resources for
                  <span className="importantHeader"> Fitness Trainers</span>
              </h2>

              <ul className="fitnessImgContainer resourceGuide">
                  <li>
                      <Link to="/blog">
                          <div className="fitnessImageContainer">
                              <img
                                  src={desk2}
                                  alt="a person typing on laptop"
                              />
                              <div className="overlay"></div>
                          </div>
                          <h3>A blog for personal trainers</h3>
                          <p>
                              This is your stop for short and potent articles
                              that can help you grow your business and stay on
                              top of trends in the fitness industry.
                          </p>
                      </Link>
                  </li>

                  <li>
                      <Link to="/forfitnessprofessionals/contentguide">
                          <div className="fitnessImageContainer">
                              <img
                                  src={contentGuideImg}
                                  alt="Guide to kick start your content creation coming soon"
                              />
                              <div className="overlay"></div>
                          </div>
                          <h3>A guide to kickstart your content creation</h3>
                          <p>
                              This includes ideas for content, templates, and a
                              1 month content calendar.
                          </p>
                      </Link>
                  </li>

                  <li>
                      <Link to="/forfitnessprofessionals/workbook">
                          <div className="fitnessImageContainer">
                              <img
                                  src={workbookImg}
                                  alt="personal training work book coming soon"
                              />
                              <div className="overlay"></div>
                          </div>
                          <h3>Download our 101 personal training workbook</h3>
                          <p>
                              This workbook includes everything you need to know
                              about starting a personal training business in
                              Toronto.
                          </p>
                      </Link>
                  </li>
              </ul>
          </div>
      </section>
  );
}

export default Resources