import blogVideo from "../assets/blogAssets/blogThumbnails/blogVideo.mp4";
import blogpost2Thumb from "../assets/blogAssets/blogThumbnails/blogPost2-thumbnail.png"
import blogpost3Thumb from "../assets/blogAssets/blogThumbnails/blogPost3-thumbnail.png";
import blogpost4Thumb from "../assets/blogAssets/blogThumbnails/blogPost4-thumbnail.png";
import blogPost5Thumb from "../assets/blogAssets/blogThumbnails/blogPost5-thumbnail.png";
import blogPost6Thumb from "../assets/blogAssets/blogThumbnails/blogPost6-thumbnail.png";
import blogPost7Thumb from "../assets/blogAssets/blogThumbnails/blogPost7-thumbnail.png";
import blogPost8Thumb from "../assets/blogAssets/blogThumbnails/blogPost8-thumbnail.png";
import blogPost9Thumb from "../assets/blogAssets/blogThumbnails/blogPost9-thumbnail.png";
import blogPost10Thumb from "../assets/blogAssets/blogThumbnails/blogPost10-thumbnail.png";
import blogPost11Thumb from "../assets/blogAssets/blogThumbnails/blogPost11-thumbnail.png";
import blogPost12Thumb from "../assets/blogAssets/blogThumbnails/blogPost12-thumbnail.png";
import blogPost13Thumb from "../assets/blogAssets/blogThumbnails/blogPost13-thumbnail.png";
import blogPost14Thumb from "../assets/blogAssets/blogThumbnails/blogPost14-thumbnail.png";
import blogPost15Thumb from "../assets/blogAssets/blogThumbnails/blogPost15-thumbnail.png";
import blogPost16Thumb from "../assets/blogAssets/blogThumbnails/blogPost16-thumbnail.png";
import blogPost17Thumb from "../assets/blogAssets/blogThumbnails/blogPost17-thumbnail.png";
import blogPost18Thumb from "../assets/blogAssets/blogThumbnails/blogPost18-thumbnail.png";
import blogPost19Thumb from "../assets/blogAssets/blogThumbnails/blogPost19-thumbnail.png";
import blogPost20Thumb from "../assets/blogAssets/blogThumbnails/blogPost20-thumbnail.png";
import blogPost21Thumb from "../assets/blogAssets/blogThumbnails/blogPost21-thumbnail.png";
import blogPost22Thumb from "../assets/blogAssets/blogThumbnails/blogPost22-thumbnail.png";
import blogPost23Thumb from "../assets/blogAssets/blogThumbnails/blogPost23-thumbnail.png";
import blogPost24Thumb from "../assets/blogAssets/blogThumbnails/blogPost24-thumbnail.png";
import blogPost25Thumb from "../assets/blogAssets/blogThumbnails/blogPost25-thumbnail.png";
import blogPost26Thumb from "../assets/blogAssets/blogThumbnails/blogPost26-thumbnail.png";
import blogPost27Thumb from "../assets/blogAssets/blogThumbnails/blogPost27-thumbnail.png";
import blogPost28Thumb from "../assets/blogAssets/blogThumbnails/blogPost28-thumbnail.png";
import blogPost29Thumb from "../assets/blogAssets/blogThumbnails/blogPost29-thumbnail.png";
import blogPost30Thumb from "../assets/blogAssets/blogThumbnails/blogPost30-thumbnail.png";

import NavBar from "../components/NavBar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../styling/BlogPage.scss";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";


const BlogPage =()=>{

    const location = useLocation()
    const elementRef = useRef(null)

    useEffect(function(){
    
        if (location.pathname === "/blog"){
        elementRef.current.scrollIntoView({
            behavior: "smooth"
        })
        }
    },[location])

    return (
        <div className="wrapper">
            <Helmet>
                <title>A Blog For Personal Trainers</title>
                <meta
                    name="description"
                    content="A blog for personal trainers"
                />
            </Helmet>

            <div className="blogSection" id="blogSection" ref={elementRef}>
                <section className="blogVideoSection">
                    <NavBar />
                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            {/* eslint-disable-next-line */}
                            <img
                                src={blogPost30Thumb}
                                alt="Text: unlocking your first client success stories as a personal trainer’ with the PLTFRM logo
"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>August 23, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                Unlocking Your First Client Success Stories as a
                                Personal Trainer
                            </h4>
                            <p>
                                In this blog post, we explore the crucial steps
                                to gather those initial client success stories
                                that can help boost your personal training
                                career. You'll learn how to set the stage for
                                success by selecting the right clients, tracking
                                progress effectively, and crafting compelling
                                narratives. These stories not only showcase your
                                skills but also serve as powerful tools to
                                attract and inspire new clients on their fitness
                                journeys.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/unlocking-your-first-client-success-stories">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>
                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            {/* eslint-disable-next-line */}
                            <img
                                src={blogPost29Thumb}
                                alt="Text: Using wellness trends to grow your personal training business’ with a graphic of a bar chart and the pltfrm logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>August 16, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                Using Wellness Trends in Toronto To Grow Your
                                Personal Training Business
                            </h4>
                            <p>
                                Discover Toronto's burgeoning wellness landscape
                                in our latest blog post. From boutique fitness
                                studios fostering community connections to
                                plant-based eateries promoting health and
                                sustainability, the city's trends are
                                transforming how Torontonians approach
                                well-being. Dive into mindfulness havens,
                                outdoor fitness gatherings, and holistic
                                wellness events that define Toronto's wellness
                                culture, and learn how incorporating these
                                elements can propel your personal training
                                business to new heights.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/using-wellness-trends-in-toronto-to-grow-your-personal-training-business">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>
                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            {/* eslint-disable-next-line */}
                            <img
                                src={blogPost28Thumb}
                                alt="A weight rack with a PLTFRM logo and text ‘Navigating Plateaus and Setback with clients’"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>August 8, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                Navigating Plateaus and Setbacks with Clients:
                                Fueling Progress and Satisfaction
                            </h4>
                            <p>
                                Plateaus and setbacks are integral to fitness
                                journeys, testing our resilience. As personal
                                trainers, we're not just guides but allies in
                                overcoming obstacles. This post emphasizes
                                understanding the science behind plateaus, the
                                emotional toll of setbacks, and the power of
                                personalized strategies. Empathetic
                                communication creates a space for doubts,
                                weaving trust and motivation.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/navigating-plateaus-and-setbacks-with-clients">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>
                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            {/* eslint-disable-next-line */}
                            <img
                                src={blogPost27Thumb}
                                alt="The PLTFRM gym with text ‘navigating payment’ and a PLTFRM logo on top"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>August 1, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                Navigating the Payment Conversation: A Guide for
                                Personal Trainers
                            </h4>
                            <p>
                                This blog provides personal trainers with a
                                comprehensive guide to confidently navigate the
                                payment conversation with clients, covering
                                essential strategies and communication
                                techniques. From setting competitive rates to
                                addressing potential concerns, this guide equips
                                trainers with the tools needed to foster
                                transparent and positive financial discussions,
                                ensuring a successful and rewarding
                                client-trainer relationship.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/navigating-the-payment-conversation">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>
                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            {/* eslint-disable-next-line */}
                            <img
                                src={blogPost26Thumb}
                                alt="Text ‘fueling a fitness business’ with an image of a girl lifting and a PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>July 14, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                Fueling Your Fitness Business: The Art of
                                Hooking and Converting Your Audience
                            </h4>
                            <p>
                                Get ready to level up your content game,
                                personal trainers! This blog post is your
                                ultimate guide to creating captivating content
                                that not only hooks but also converts your
                                audience into loyal clients. We'll dive into
                                attention-grabbing techniques, powerful
                                calls-to-action, and clever strategies that will
                                make your content irresistible. Get ready to
                                unleash your creativity and turn readers into
                                raving fans with content that packs a punch!
                            </p>
                            <button>
                                <Link to="/blog/blogPost/fueling-your-fitness-business">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost25Thumb}
                                alt="Graphic of a woman lifting weights with text ‘I see a strong woman"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>July 5, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                Ola ON: body image, fitness goals, and how to
                                pick a trainer
                            </h4>
                            <p>
                                Join us for a, straight to the point,
                                conversation with Ola. Ola is a trainer and UX
                                designer in Toronto and we had the privilege of
                                spending some time picking her brain. This
                                conversation is for anyone interested in fitness
                                and for any trainer just starting off.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/ola-on-body-image-fitness-goals-and-how-to-pick-a-trainer">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost24Thumb}
                                alt="Text: emotional intelligence and the PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>June 26, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>Social Media for Personal Trainers</h4>
                            <p>
                                We’re getting into a not so popular social
                                platform for personal trainers: Linkedln. We go
                                through why and how Linkedln can help you grow
                                your personal training business and teach you
                                everything you need to know to optimize your
                                Linkedln profile to get started.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/social-media-for-personal-trainers">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost23Thumb}
                                alt="Text: emotional intelligence and the PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>June 20, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                The Emotionally Intelligent Personal Trainer
                            </h4>
                            <p>
                                Emotional intelligence is important for personal
                                trainers for their own lives and for their
                                business. In this article we discuss why
                                personal trainers need to prioritize emotional
                                intelligence and the ways it can help you be a
                                better trainer and business owner.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/the-emotionally-intelligent-personal-trainer">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost22Thumb}
                                alt="Text ‘exceptional customer service personal trainer edition’ with a personal trainer wearing a t-shirt with the PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>June 12, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>Are There Too Many Personal Trainers?</h4>
                            <p>
                                If you are thinking about becoming a personal
                                trainer but worried that the training industry
                                is too saturated you need to read this article.
                                We believe that if you have passion for fitness
                                and training you can still build a successful
                                business in today’s competitive environment. We
                                are excited to share why and how you can do
                                that!
                            </p>
                            <button>
                                <Link to="/blog/blogPost/are-there-too-many-personal-trainers">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost21Thumb}
                                alt="Text ‘exceptional customer service personal trainer edition’ with a personal trainer wearing a t-shirt with the PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>May 26, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                The New Age of Personal Training: And it goes
                                way beyond fitness
                            </h4>
                            <p>
                                If you are struggling to stay in the box of what
                                you’ve been told a personal trainer should do,
                                this article is for you. The way personal
                                trainers are being viewed is changing and it’s
                                changing fast. The possibilities of how you can
                                run a business and share your love for fitness
                                are countless. Let’s dive into these changes and
                                talk about what they mean for you and your
                                fitness business.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/the-new-age-of-personal-training">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost20Thumb}
                                alt="Text ‘exceptional customer service personal trainer edition’ with a personal trainer wearing a t-shirt with the PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>May 23, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                Exceptional Customer Service: Personal Trainer
                                Edition
                            </h4>
                            <p>
                                If you are a personal trainer that is struggling
                                with how to communicate with clients this blog
                                post is for you. We break down the complexities
                                of sales and marketing into understandable terms
                                you need to focus on in order to provide better
                                customer service. Whether it’s online or
                                in-person communication you need to work on this
                                post will start you off in the right direction.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/exceptional-customer-service">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost19Thumb}
                                alt="Emily Dugan with the text “Emily Dugan ON: depression, strength training, infradian cycles, and empowering women in the fitness world” and the PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>May 9, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                Emily Dugan ON: depression, strength training,
                                infradian cycles, and empowering women in the
                                fitness world
                            </h4>
                            <p>
                                Emily Dugan is on a quest to empower women with
                                knowledge of their cycles and how they can use
                                it to inform their performance in the gym. Her
                                vivacious energy is palpable and her passion for
                                her work is admirable. In this article, Emily
                                shares her personal journey and story and spills
                                all the deets on the new brand she building.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/Emily-Dugan-on-women-and-fitness">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost18Thumb}
                                alt="Personal trainer KD with kettlebells and text “a conversation with KD about life & basketball” with a PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>May 12, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                A Conversation with KD about Life and Basketball
                            </h4>
                            <p>
                                Join us this week for a conversation with KD,
                                aka{" "}
                                <a href="https://www.instagram.com/ballsnbells/">
                                    @ballsnbells
                                </a>
                                . KD is building a career he loves that began
                                from a childhood dream. He sat down with our
                                team to share his struggles, beliefs, and
                                wisdom. This is a great article for anyone at
                                the start of their career trying to find some
                                direction.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/a-conversation-with-KD-about-life-and-basketball">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost17Thumb}
                                alt="Text: 'The business of client acquisition and the human side of it' and a graphic with a of a group of people doing a yoga pose."
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>April 27, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                The Business Side of Client Acquisition and The
                                Human Side of It
                            </h4>
                            <p>
                                You should never feel bad about actively looking
                                for new clients. Your business needs clients in
                                order to be successful. In this blog post, we
                                get right into it and talk about how you can
                                acquire clients at any stage in your business.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/the-business-side-of-client-acquisition">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost16Thumb}
                                alt="The two communities every personal trainer must build."
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>April 20, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                How Important is Community for Personal
                                Trainers: A strategy to build community
                            </h4>
                            <p>
                                This week we dive into what life and work are
                                like as an entrepreneur and why they need to
                                prioritize community. We also talk about what
                                the two communities are that personal trainer
                                needs to build for themselves in order to grow a
                                successful business.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/the-two-communities-every-personal-trainer-must-build">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost15Thumb}
                                alt="4 Things to keep in mind when setting your 1:1 rates."
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>April 13, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                A Flexible Workday: How to make the most out of
                                your working hours
                            </h4>
                            <p>
                                Anyone who thinks the life of an entrepreneur is
                                ‘easy’ has obviously never been one. In this
                                post, we take you through the pros and cons of
                                being able to set your own schedule. We also
                                share some ways you can make the most of the
                                freedom you have as an entrepreneur. This is an
                                article for all our trainers who are ready to
                                get to work growing their businesses.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/how-to-make-the-most-out-of-your-working-hours">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost14Thumb}
                                alt="4 Things to keep in mind when setting your 1:1 rates."
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>April 6, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                We know you are doing this for more than Just
                                the Money
                            </h4>
                            <p>
                                If you are a personal trainer we know you are
                                passionate about the work you do. You can’t be a
                                good personal trainer unless you really love
                                helping people. If this is you, congrats. You
                                are doing work you love and that is a victory in
                                itself. Let us help you focus on how you can
                                support yourself better and smarter now!
                            </p>
                            <button>
                                <Link to="/blog/blogPost/we-know-you-are-doing-this-for-more-than-just-the-money">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost13Thumb}
                                alt="Bahar Sabour with text “ a conversation with Bahar Sabour” and the PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>Marth 28, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                You Are Never Too Young To Follow Your Passions
                                with Bahar Sabour
                            </h4>
                            <p>
                                If you struggle to motivate yourself you need to
                                read this article. Bahar Sabour sat down with
                                our team and shared how she started her career
                                as a personal trainer at a young age. Bahar’s
                                vibrant and youthful energy is magnetizing. We
                                thoroughly enjoyed our conversation with her and
                                the wisdom she had to share.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/you-are-never-too-young-to-follow-your-passions">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost12Thumb}
                                alt="Graphic of two people with chat boxes and text “The Art of Communication: How to Communicate Effectively with Your Clients for Personal Trainers"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>Marth 20, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                The Art of Communication: How to Communicate
                                Effectively with Your Clients for Personal
                                Trainers
                            </h4>
                            <p>
                                A big part of running a successful personal
                                training business will come down to effective
                                communication skills. As a personal trainer, you
                                need to be able to inspire and motivate your
                                clients. This blog post will give you 4 ways you
                                can master the art of communication.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/the-art-of-communication">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost11Thumb}
                                alt="Taranpreet Singh at the gym with text ‘@thevintagesingh on all things fitness’ and a PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>March 15, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>Fitness as a Lifestyle with Taran Singh</h4>
                            <p>
                                We had a conversation with Taran Singh, a
                                personal trainer dedicated to inspiring his
                                clients to focus on changing their lifestyles
                                instead of focusing solely on their physiques.
                                We dive into all things personal training,
                                nutrition, and fitness. We promise you this is
                                an article you don’t want to miss!
                            </p>
                            <button>
                                <Link to="/blog/blogPost/fitness-as-a-lifestyle">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost10Thumb}
                                alt="Graphic of a phone and social media icons with text “ the role of social media in building a successful personal training business” and the PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>March 7, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                The Role of Social Media In Building a
                                Successful Training Business
                            </h4>
                            <p>
                                Social media plays a crucial role in building a
                                successful personal training business. In this
                                blog post we take you through some of the ways
                                social media can help you start or expand your
                                business.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/the-role-of-social-media">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost9Thumb}
                                alt="Personal trainer Joanne Lam with text featuring @joannelamfitness and the PLTFRM logo"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>Feb 21, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                A Conversation about Movement and Longevity with
                                Joanne Lam
                            </h4>
                            <p>
                                An in-depth conversation with Joanne Lam about
                                team sports, movement, and longevity. If you are
                                someone wondering if you are ‘too old’ to play a
                                sport you love, this blog post is for you!
                            </p>
                            <button>
                                <Link to="/blog/blogPost/movement-and-longevity">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost8Thumb}
                                alt=" Do this to grow your business” with the PLTFRM logo and a graphic"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>Feb 14, 2023</p>
                                <p>3 min read</p>
                            </div>
                            <h4>
                                What Every Personal Trainer Should be Doing to
                                Grow Their Business
                            </h4>
                            <p>
                                Ready to take your business growth to the next
                                level? In this post, we share the one thing you
                                should be focusing on as a personal trainer to
                                grow your business. Sweet, simple, and to the
                                point: this blog post could change your business
                                by helping you find your focus.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/do-this-to-grow-your-business">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost7Thumb}
                                alt="4 best tools for online businesses with a PLTFRM logo and a graphic.."
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>Feb 6, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                The Journey to Full-time Personal Trainer with
                                Jenny Lam
                            </h4>
                            <p>
                                We had a pleasure of speaking to and
                                interviewing{" "}
                                <a href="https://www.instagram.com/soulmatefit/?hl=en">
                                    @soulmatefit
                                </a>{" "}
                                on her journey. Jenny shared how she built a
                                successful personal training business for
                                herself and tips for anyone desiring to do the
                                same.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/the-journey-to-full-time-personal-trainer">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost6Thumb}
                                alt="4 best tools for online businesses with a PLTFRM logo and a graphic.."
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>January 30, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>4 Best Tools for Online Businesses</h4>
                            <p>
                                As a business owner having the right tools is
                                very important. We’ve put together a list of
                                free tools that will help you run a successful
                                online business. There is so much available at
                                the tip of your fingers and now you have a list
                                to make it even easier!
                            </p>
                            <button>
                                <Link to="/blog/blogPost/4-best-tools-for-online-businesses">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogPost5Thumb}
                                alt="We put together some of the top takeaways from the 2022 canfitpro Health and Fitness Trends Survey. Stay up to date with what's happening in the fitness industry in Canada."
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>January 23, 2023</p>
                                <p>4 min read</p>
                            </div>
                            <h4>
                                4 Steps to Start a Fitness Training Business in
                                Toronto
                            </h4>
                            <p>
                                Starting a business can seem overwhelming and
                                challenging. We are here to make it simple for
                                you. This blog post discusses insurance
                                coverage, registering your business, and some of
                                the exciting parts of being a business owner.
                                Stop making excuses and start building your
                                business today!
                            </p>
                            <button>
                                <Link to="/blog/blogPost/4-tips-to-start-a-fitness-business">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogpost4Thumb}
                                alt="Competitive bodybuilder Adrienne leaning against wall, smiling.  PLTFRM logo with text `a conversation with @fitadrienne`"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>January 16, 2023</p>
                                <p>3 min read</p>
                            </div>
                            <h4>
                                Building Confidence and Resilience in a
                                Competitive World
                            </h4>
                            <p>
                                A conversation with{" "}
                                <a href="https://www.instagram.com/fitadrienne/">
                                    @fitadrienne
                                </a>{" "}
                                on how she started her competitive bodybuilding
                                career.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/building-confidence-and-resilience-in-a-competitive-world">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogpost3Thumb}
                                alt="We put together some of the top takeaways from the 2022 canfitpro Health and Fitness Trends Survey. Stay up to date with what's happening in the fitness industry in Canada."
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>January 2, 2023</p>
                                <p>3 min read</p>
                            </div>
                            <h4>
                                Key Takeaways from the 2022 canfitpro Health and
                                Fitness Trends Survey
                            </h4>
                            <p>
                                We put together some of the top takeaways from
                                the 2022 canfitpro Health and Fitness Trends
                                Survey. Stay up to date with what's happening in
                                the fitness industry in Canada.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/key-takeaways-from-2022-canfitpro-survey">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <img
                                src={blogpost2Thumb}
                                alt="Wondering how to grow your client list now that you are a certified personal trainer? Don’t worry, we’ve got you covered. Check out our blog post to start growing that client list today!"
                            />
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>December 26, 2022</p>
                                <p>3 min read</p>
                            </div>
                            <h4>
                                3 Tips for Fitness Trainers Growing Their Client
                                List
                            </h4>
                            <p>
                                Wondering how to grow your client list now that
                                you are a certified personal trainer? Don’t
                                worry, we’ve got you covered. Check out our blog
                                post to start growing that client list today!
                            </p>
                            <button>
                                <Link to="/blog/blogPost/tips-for-fitness-trainers">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>

                    <div className="blogContainer1">
                        <div className="blogThumbContainer">
                            <video
                                src={blogVideo}
                                autoPlay
                                muted
                                loop
                                playsInline
                                type="video/mp4"
                                id="blogVideo"
                                title="4 Ways to Grow Your Fitness Business on Social Media"
                            ></video>
                        </div>
                        <div className="blogPostDetails">
                            <div className="postData">
                                <p>December 19, 2022</p>
                                <p>3 min read</p>
                            </div>
                            <h4>
                                4 Ways to Grow Your Fitness Business on Social
                                Media
                            </h4>
                            <p>
                                A strategy you can implement today to start
                                growing your business on social media.
                            </p>
                            <button>
                                <Link to="/blog/blogPost/grow-your-business">
                                    Read More
                                </Link>
                            </button>
                            <p className="authorTag">By PLTFRM</p>
                        </div>
                    </div>
                </section>

                <section className="followUs">
                    <div className="followContent">
                        <h2>Follow Us</h2>
                        <p>on Social Media</p>

                        <ul className="socials">
                            <li>
                                <a
                                    href="https://twitter.com/PLTFRMfit"
                                    className="twitterIcon"
                                >
                                    <FontAwesomeIcon
                                        icon={faTwitter}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">
                                        Twitter Icon
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/profile.php?id=100087595012867"
                                    className="iconCircle"
                                >
                                    <FontAwesomeIcon
                                        icon={faFacebook}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">
                                        Facebook Icon
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/pltfrm.fit/?hl=en"
                                    className="iconCircle"
                                >
                                    <FontAwesomeIcon
                                        icon={faInstagram}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">
                                        Instagram Icon
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/pltfrmfit"
                                    className="iconCircle"
                                >
                                    <FontAwesomeIcon
                                        icon={faLinkedin}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">
                                        LinkedIn Icon
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="http://www.tiktok.com/@pltfrm.fit"
                                    className="tiktokIcon"
                                >
                                    <FontAwesomeIcon
                                        icon={faTiktok}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">Tiktok Icon</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default BlogPage