import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import canva from "../assets/blogAssets/blogPost6-canva.png";
import calendly from "../assets/blogAssets/blogPost6-calendly.png";
import slice from "../assets/blogAssets/blogPost6-slice.png";
import trello from "../assets/blogAssets/blogPost6-trello.png";
import blogImg from "../assets/blogAssets/blogPost6-img1.png";
import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

const BlogContent6 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/4-best-tools-for-online-businesses"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
    return (
        <>
            <Helmet>
                <title>4 Best Tools for Online Businesses</title>

                <meta
                    name="description"
                    content="As a business owner having the right tools is very important. We’ve put together a list of free tools that will help you run a successful online business. There is so much available at the tip of your fingers and now you have a list to make it even easier!"
                />
            </Helmet>

            <h2 ref={elementRef}>4 Best Tools For Online Businesses</h2>

            <div className="authorContainer">
                <h4>January 30th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <p>
                Running a business is more possible than ever before. There are
                many tools and software in the market right now that make
                starting and growing a business something almost anyone can do.
                The difference between someone who will do it and someone who
                won’t is their attitude and determination. If you are here, we
                know you’ve got both of those in check. We’ve put together a
                list of software, some are apps, some are websites, to help you
                grow your fitness business.
            </p>

            <p>
                The tools in this list will also help you organize yourself,
                your tasks, and your process so that you can focus on being a
                good fitness trainer. The ‘selling’ and ‘marketing’ part of your
                business is just one side. We, at PLTFRM, know and understand
                that! The service that you provide takes a lot of your energy
                and time, so we want to make the rest of the process simple for
                you!
            </p>

            <h3 className="blogSubTitle">4 Tools to help run your business</h3>

            <h3>1. Canva</h3>

            <img className="blogImg" src={canva} alt="Canva Logo" />

            <p>
                <a href="https://www.canva.com/">Canva </a>is a tool that will
                take your content creation to the next level! If you have no
                design experience there are templates available that you can
                customize to your liking. Canva has a free and paid version
                available. This makes it a tool that is accessible for any
                budget. There is a website and a phone app available which means
                you can create content from anywhere and at any time.
            </p>

            <h3>2. Calendly</h3>

            <img className="blogImg" src={calendly} alt="Calendly Logo" />

            <p>
                You might have to do many calls remotely with an online
                business. Whether you are training clients online or offering
                one-off services. <a href="https://calendly.com/">Calendly </a>
                is a tool for your audience to book time in your schedule.
                Calendly also has a payment gateway, making it an excellent tool
                through which prospective clients can book your services.
                Calendly has a free and paid version. You no longer have to go
                back and forth looking for time in your schedule. All you have
                to do is set your hours and send a link to the client so they
                can book a slot that works for them and you!
            </p>

            <h3>3. Splice</h3>

            <img className="blogImg" src={slice} alt="Slice Logo" />

            <p>
                There are many video editing tools available. Splice is a
                favorite at PLTFRM. Splice is an app that has a free and
                subscription-based plan. A lot of your video editing will often
                happen within social media apps but we also know there are some
                things you simply can’t do on the Instagram or TikTok interface.
                This is where Splice comes in!
            </p>

            <h3>4. Trello</h3>

            <img className="blogImg" src={trello} alt="Trello Logo" />

            <p>
                <a href="https://trello.com/">Trello </a>is an organizational
                tool. We love using Trello to help us organize our content
                creation process. We also love that we can organize our daily
                tasks through it. Trello can be a way you keep all your to-do
                lists in one place. It is a great collaborative tool if you work
                with a team. Trello is available for free or at a fee if you
                would like access to its more advanced integrations and
                features.
            </p>

            <p>
                At PLTFRM, we know there are times when running a business can
                seem overwhelming. We also believe that it is possible to grow a
                successful business. We’ve created a guidebook to help your
                content creation process. This FREE guidebook has everything you
                need to start creating content that converts. Download our
                guidebook today to take your content creation process to the
                next level!
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogContentGuide">
                <img
                    className="blogImg"
                    src={blogImg}
                    alt="Trainer wearing a PLTFRM T-shirt with text: a guide to content creation for fitness trainers"
                />
                <button>
                    <Link to="/contentguide">Download Guidebook</Link>
                </button>
            </div>

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/the-journey-to-full-time-personal-trainer">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/4-tips-to-start-a-fitness-business">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent6;
