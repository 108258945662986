import blogpostImg1 from "../assets/blogAssets/blogPost2-img1.png"
import blogpostImg2 from "../assets/blogAssets/blogPost2-img2.png";
import blogpostImg3 from "../assets/blogAssets/blogPost2-img3.png";
import ShareButtons from "../components/ShareButtons";


import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const BlogContent2 = () => {

    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname === "/blog/blogPost/tips-for-fitness-trainers") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
    return (
        <>
            <Helmet>
                <title>
                    3 Tips for Fitness Trainers Growing Their Client List |
                    PLTFRM - Opening Early 2023
                </title>

                <meta
                    name="description"
                    content="Wondering how to grow your client list now that you are a personal trainer? Don't worry, we've got you covered. Check out our blog post to start growing that client list today!"
                />
            </Helmet>

            <h2 ref={elementRef}>
                3 Tips for Fitness Trainers Growing Their Client List
            </h2>

            <div className="authorContainer">
                <h4>December 26th, 2022</h4>
                <p>by PLTFRM</p>
            </div>

            <p>Is this you?</p>

            <p>
                You are a certified fitness trainer with multiple
                certifications. You train and you love training. Fitness is your
                business, so it's important to always be thinking about ways to
                grow your business. After all, your business is your livelihood!
                We understand how important your business is to you and what you
                to remember that there are lots of things you can do to grow
                your client list. Here are 3 tips to get you started.
            </p>

            <h3>Tip 1: Word of mouth: make one connection, attract more</h3>
            <img
                className="blogImg"
                src={blogpostImg1}
                alt="Graphic showing a man speaking. Two bodies are standing and listening."
            />

            <p>
                When growing your client list, remember that word-of-mouth is
                still the most powerful marketing tool available. Make sure you
                are providing exemplary service to every one of your clients so
                they will be excited to tell others about you:
            </p>
            <ul className="blogpostList">
                <li>
                    <p>
                        Add a personal touch to each text or email you send to
                        your clients. Use each of their names when writing to
                        them. Learn about their lives and celebrate their
                        milestones. This is easier to do when you have fewer
                        clients.
                    </p>
                </li>
                <li>
                    <p>
                        When you’re training, remember that you’re on. It’s not
                        enough that a program is effective. When a trainee is
                        working with a trainer, that trainee deserves your full
                        attention and a rapport that makes them look forward to
                        seeing you each session.
                    </p>
                </li>
                <li>
                    <p>
                        Send text confirmations in advance of each training
                        session. Your clients will appreciate the reminder or
                        opportunity to cancel. If your host facility can
                        automate this for you, that’s a great bonus.
                    </p>
                </li>
            </ul>

            <p>
                Remember that your client hasn’t just hired you for your fitness
                expertise. Whether they tell you so or not, they’re your client
                for as long as they feel taken care of. The fitness business is
                a service business, and services businesses succeed on personal
                recommendations.
            </p>

            <h3>Tip 2: Tap into new and existing networks</h3>

            <img
                className="blogImg"
                src={blogpostImg2}
                alt="Graphic showing people connected through networks."
            />

            <p>
                As a fitness trainer, try to get involved or linked with
                organizations related to your industry. This will help raise
                awareness about your brand and what you have to offer potential
                clients. The most obvious network is your host gym. Consider
                what value your workplace adds to your referral network.
            </p>
            <p>
                You should also consider your existing social circles. For
                example, if you’ve worked as a bartender or waiter in the past,
                the connections you have in the service industry can provide
                clients in the fitness business. Your old industry connections
                will help you recruit work in fitness. Make sure they know about
                your new gig!
            </p>

            <h3>Tip 3: Consistent social media presence</h3>

            <img
                className="blogImg"
                src={blogpostImg3}
                alt="Graphic with a phone in the center connected to 4 social media icons."
            />

            <p>
                When growing your client list, don't forget about the power of
                social media - make sure you are active on platforms like
                <a href="https://www.facebook.com/"> FaceBook </a>and
                <a href="https://www.instagram.com/"> Instagram </a>
                so people can learn more about you and what you do. When people
                hear about you, they want to be able to look you up! A
                fitness-oriented social media presence of more than a few months
                adds to your credibility.
            </p>
            <p>
                Your content on social media should reflect your life,
                personality, and training style. You won’t want to post about
                things you aren’t already interested in. Remember, when your
                schedule starts to thin out, you have more time to share
                content! When you feel like you don’t have enough personal
                clients, you have the time to put yourself out there to get
                more. Consider spaces where you can make visually engaging
                fitness content.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/key-takeaways-from-2022-canfitpro-survey">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/grow-your-business">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent2;
