import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost25-img1.png";

const BlogContent25 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/ola-on-body-image-fitness-goals-and-how-to-pick-a-trainer"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>
                    Ola ON: body image, fitness goals, and how to pick a trainer
                </title>

                <meta
                    name="description"
                    content="Join us for a, straight to the point, conversation with Ola. Ola is a trainer and UX designer in Toronto and we had the privilege of spending some time picking her brain. This conversation is for anyone interested in fitness and for any trainer just starting off."
                />
            </Helmet>
            <h2 ref={elementRef}>
                Ola ON: body image, fitness goals, and how to pick a trainer
            </h2>
            <div className="authorContainer">
                <h4>July 5, 2023</h4>
                <p>by PLTFRM</p>
            </div>
            {/* eslint-disable-next-line */}
            <img
                className="blogImg"
                src={blogImage}
                alt="Personal trainer Ola with text ‘ ola on: body image, fitness goals, and how to pick a trainer’ with a PLTFRM logo"
            />
            <p>
                The PLTFRM team recently got to speak with Ola, aka{" "}
                <a href="https://www.instagram.com/ola.tsev/">@ola.tsev</a> on
                Instagram. We had an indepth and inspirational conversation
                about fitness, body image, and on being a good personal Trainer.
            </p>
            <p>
                Ola has a confident and powerful energy as a person and a
                trainer that makes you believe that she knows what she is doing
                and that you can trust her to give it to you straight.
            </p>
            <p>
                We started by asking Ola about where her fitness journey started
                from and she shared how she spent her whole life playing some
                sport or the other. “ Gymnastics was probably my pain sport but
                if you can name a sport I’ve probably played it.”
            </p>
            <p className="blogQuoteText">
                <span className="tab"></span>
                “You can never 100% look like someone else. There are things
                like genetics that go into that. So it’s important to look at
                hiring a trainer holistically and make sure they are right fit
                for you and where you are in your journey.”
            </p>
            <p>
                Ola went on to share that in her teen years, growing up in
                Eastern Europe, she felt a lot of pressure to look a certain
                way. She told us, “ I joined a gym and started fitness because I
                wanted to change my body. I wanted to reach my weight goals. I
                started with a very unhealthy situation and relationship with
                fitness and it’s changed over time.”
            </p>
            <p>
                The PLTFRM team asked when the ‘change’ in her relationship with
                fitness happened and Olga shared that for her everything changed
                when she moved to Canada. “ When I came to Canada I joined a
                small boutique gym that was very focused on strength and on
                feeling good instead of solely focusing on the way you look and
                that community really started to change my mindset around
                fitness.”
            </p>
            <p>
                We asked Ola about what her relationship with fitness was today,
                and she shared, “the ‘why’ behind my own training routine is to
                feel and be stronger, continue building muscle, support my
                mental health and love the way I look - jacked and strong haha.”
                Olga told us how in fitness she thinks it's normal okay for the
                goals to be based on the physical body - this is where most
                folks start.
            </p>
            <p>
                That being said, she also believes how you perform will affect
                your every day life and your mind. “When you are at the gym you
                go through physical pain and your body learns to adapt. I
                believe going through that pain helps you deal with or even
                eases other life pains slightly. You are more ready and able to
                deal with other challenges in life.”
            </p>
            <p className="blogQuoteText">
                <span className="tab"></span>
                “It only becomes a problem when physical change is your sole
                goal and the means to achieve them entail hurting your mental
                and/or physical health”
            </p>
            <p>
                Ola then expanded on her thoughts on people having physical body
                goals in relation to fitness. She is a big believer that you
                shouldn’t feel bad for wanting to change your body. “ I believe
                it only becomes a problem when physical change is your sole goal
                and the means to achieve them entail hurting your mental and/or
                physical health. You also need to have some other goals that
                will keep you going when you see or feel stagnation of the
                aesthetic related goals.”
            </p>
            <p>
                We dove into a conversation about what it takes to be a good
                personal trainer. As someone with good insight on herself and
                her abilities, Ola was able to share with us a really valuable
                perspective on this topic. “In my opinion, as a personal trainer
                you have to be relatively healthy and fit. Healthy and fit can
                look and be different for everyone but you must strive for
                becoming a better version of yourself and practice what you
                preach to your clients.”
            </p>
            <p className="blogQuoteText">
                <span className="tab"></span>
                “People are often looking for someone they can see themselves in
                because that’s who they feel connected to.”
            </p>
            <p>
                We then asked Ola for her definition of healthy and she shared,“
                being holistically healthy includes good physical health. You
                need to also be in a good place mentally. You need to understand
                what you body needs and what being healthy looks like for you.”
                Ola shared how it’s important to be aware of yourself as a
                trainer because often the clients that come to you will relate
                to your story in some way. People are often looking for someone
                they can see themselves in because that’s who they feel
                connected to. “For example,I, myself, have quite a few clients
                who came to Canada from Eastern Europe as well. They come to me
                because I understand them better on a cultural level, and they
                may be able to better relate to me.”
            </p>
            <p>
                We then asked Ola what clients should look for when hiring a
                trainer. She shared an important point, “ it’s important for
                people to realise that they shouldn’t solely be hiring trainers
                based on how they look. If you are hiring a trainer cause you
                want to look like them, you are setting yourself up for
                disappointment. You can never 100% look like someone else. There
                are things like genetics that go into that. So it’s important to
                look at hiring a trainer holistically and make sure they are the
                right fit for you and where you are on your journey.”
            </p>
            <p>
                We thoroughly enjoyed our conversation with Ola and are grateful
                for her taking time to chat with us. We are excited to watch her
                grow and continue to soak up all the insight and tips she has to
                offer. If you would like to connect with Ola you can find her on
                IG <a href="https://www.instagram.com/ola.tsev/">@ola.tsev</a>
            </p>
            <ShareButtons url={location.pathname} />
            
            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/fueling-your-fitness-business">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/social-media-for-personal-trainers">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent25;
