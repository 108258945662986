import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import blogImg from "../assets/blogAssets/blogThumbnails/blogPost7-thumbnail.png";
import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

const BlogContent7 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/the-journey-to-full-time-personal-trainer"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
    return (
        <>
            <Helmet>
                <title>
                    The Journey to Full-time Personal Trainer with Jenny Lam
                </title>

                <meta
                    name="description"
                    content="We had a pleasure of speaking to and interviewing @soulmatefit on her journey. Jenny shared how she built a successful personal training business for herself and tips for anyone desiring to do the same."
                />
            </Helmet>

            <h2 ref={elementRef}>
                The Journy to Full-time Personal Trainer with Jenny Lam
            </h2>

            <div className="authorContainer">
                <h4>February 6th, 2023</h4>
                <p>by PLTFRM</p>
            </div>
            <img className="blogImg" src={blogImg} alt="Jenny Lam in a gym" />
            <p>
                We recently sat down with with Jenny Lam, aka{" "}
                <a href="https://www.instagram.com/soulmatefit/?hl=en">
                    @soulmatefit
                </a>{" "}
                on Instagram. We had a wonderful conversation about life and
                fitness, and how Jenny became a successful full-time personal
                trainer. Jenny caught our eye on social media because of her
                wholesome, inspirational, and educational content.
            </p>

            <p>
                Jenny is a certified personal trainer and nutritional coach
                based in Toronto. She has been a coach for over 7 year now and
                believes her mission is to help her clients become the
                healthiest versions of themselves so that they can live their
                best life. As we dove into the journey that led Jenny down this
                path, she shared how she used to be waitress and led, what she
                would described as, an unhealthy lifestyle. “I used to get sick
                all the time. I had a nagging cough that never went away. Can
                you imagine having a nagging cough right now during COVID? It
                was totally unacceptable.” Jenny decided to go see a doctor who
                prescribed an inhaler. She shared how the moment she held the
                inhaler she had a realization that this could not be how she
                lived the rest of life. “I didn’t want to be this person who
                would be carrying an inhaler for the rest of her life.”{" "}
            </p>

            <p>
                At 28 years old, Jenny decided it was time she began taking care
                of herself. “It started with signing up for a gym membership and
                just staying consistent with going to the gym. I fell in love
                with it. I loved how I felt after going to the gym.” Jenny
                described how this led to her improving her nutritional habits,
                which led to better sleep, and over time she simply started
                feeling better.
            </p>

            <p>
                At this point in her life Jenny was still waitressing but also
                knew she didn’t want to be one forever. It wasn’t until she feel
                in love with fitness that she felt inspired enough to leave that
                role and pursue personal training. “At that time I didn’t know
                any successful personal trainers. The only people I knew were
                those who worked at Goodlife. Then one day, I met a successful
                personal trainer and he began coaching me, and that’s how it all
                started for me.”
            </p>

            <p>
                We went on to speak to Jenny about her passion for training. She
                shared how her favorite part of this job goes beyond just
                helping her clients achieve their goals. “I love seeing how much
                coaching carries over into their everyday lives. For example,
                one time a client felt so confident in her own skin that she was
                brave enough to ask for a raise. Another client, was feeling so
                strong in her body that she left a toxic relationship.” Jenny
                shared multiple stories similar to these that she says fuels her
                passion and drive to continue showing up for this work.
            </p>

            <p>
                We asked Jenny if she had any advice for aspiring fitness
                trainers looking to building a personal training business. She
                talked about how someone was passionate about training should
                pursue it. They could start small if they desired, they don’t
                have to leave their full-time job at first. It is however
                important to just get started, and build on that. Jenny talked
                about how she didn’t leave her job as a waitress right away. “I
                used to waitress during the day and train clients in the
                evening.” Jenny shared how she did this until her personal
                training career really kicked off in year two or three of her
                fitness career.
            </p>

            <p>
                Towards the end of our time with Jenny, we talked about women in
                the weightlifting space. Jenny shared how she believes it is so
                great to see so many women now weightlighting. “For an Asian
                woman, the women we grew up with weren’t lifting weights.” Jenny
                shared how her mom’s diagnosis with ostreoperosis helped her
                realise it was more important for her to start lifting weights
                and be strong, and simply focus on having an able body so that
                she could be independent. Jenny talked how she and her husband
                subscribe to glass waterbottles that get delivered twice a week.
                These bottles are at least 11 liters. “I don’t even think twice
                about how heavy it is when I need to change the water because
                it’s a part of my routine and I’m able to do it.” Jenny went on
                to say how she believes being strong is empowering because it
                lets women do things on their own.
            </p>

            <p>
                Speaking to Jenny was inspiring and motivational. Jenny is
                someone who had a dream and went after it head on. She is
                someone we are so excited to continue to follow and celebrate as
                her career grows. If you’d like to connect with Jenny you can
                find her on Instagram{" "}
                <a href="https://www.instagram.com/soulmatefit/?hl=en">
                    @soulmatefit.
                </a>{" "}
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/do-this-to-grow-your-business">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/4-best-tools-for-online-businesses">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
    
};

export default BlogContent7;
