import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import ShareButtons from "../components/ShareButtons";


import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const BlogContent3 = () => {

    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname === "/blog/blogPost/key-takeaways-from-2022-canfitpro-survey"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <>
            <Helmet>
                <title>
                    Key Takeaways from the 2022 canfitpro Health and Fitness
                    Trends Survey
                </title>

                <meta
                    name="description"
                    content="We put together some of the top takeaways from the 2022 canfitpro Health and Fitness Trends Survey. Stay up to date with what's happening in the fitness industry in Canada."
                />
            </Helmet>

            <h2 ref={elementRef}>
                Key Takeaways from the 2022 canfitpro Health and Fitness Trends
                Survey
            </h2>
            <div className="authorContainer">
                <h4>January 2nd, 2023</h4>
                <p>by PLTFRM</p>
            </div>
            <p>
                Every year, the canfitpro Health and Fitness Trends Survey
                invites responses from more than 54,000 fitness professionals
                and over 3,500 business owners across Canada, and they’ve just
                released the data in the{" "}
                <a href="https://www.canfitpro.com/trendsreport/">
                    2022 Fitness Trends Report.
                </a>{" "}
                Here are some highlights that should inform your fitness career!
            </p>

            <h3>Online Services Will Continue to Trend Upwards</h3>

            <p>
                About 42% of fitness businesses now deliver services at least
                partially online. This reflects the growing opportunity for
                remote or hybrid work in our industry. Consider your resources
                for recording or hosting online content.
            </p>

            <h3>
                Fitness Trainers in 2022 Were Motivated by More Than Just Money
            </h3>

            <p>
                Fitness professionals care about more things than just
                compensation when deciding where to work. In this survey, 39%
                said that workplace culture matters most when looking for work.
            </p>

            <p>
                Keep in mind that your future colleagues are your future pool of
                mentors, friends, and business contacts. If you want to work
                with entrepreneurial and motivated people, you’ll need to get a
                sense of the vibe at whatever new gig you’re thinking about. Do
                they love their job like you do?
            </p>

            <h3>canfitpro Shared the Top Trends for 2023</h3>

            <p>
                Respondents were also asked what themes are trending in the
                fitness industry. Bodyweight training, balance-stability
                training, and primal movement patterning — 43% of survey
                respondents answered{" "}
                <a href="https://www.opexfit.com/blog/what-is-functional-fitness">
                    functional fitness
                </a>{" "}
                as a trend for the coming year. That’s the top response!
            </p>

            <p>
                The second most popular answer was active aging adult programs.
                According to the survey, 39% of the industry say there will be
                increasing demand for programming for the aging population. The
                senior clientele are no longer a special population. It may be
                time to up-skill in seniors’ training.
            </p>

            <p>
                At third place, 34% agree that freestyle group fitness remains
                on trend. That includes small groups, boot camps, circuit
                training, cycling, and dance. Many clients will still enjoy
                pursuing fitness in social settings. The{" "}
                <a href="https://www.canfitpro.com/">canfitpro</a> report notes
                that, for many, group fitness is an entry point into the fitness
                world before setting into a gym.
            </p>

            <h3>Fitness Professionals Are Here to Stay Says canfitpro</h3>

            <p>
                An overwhelming majority of fitness professionals, 78%, say they
                are likely to work in the fitness industry in the coming years.
                As a personal trainer, you’re part of a community of committed
                professionals and entrepreneurs, all striving to bring wellness
                to the masses! In case you were ever in doubt, these retention
                statistics show you’re one of many who are planning for
                long-term goals doing work they love.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/building-confidence-and-resilience-in-a-competitive-world">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/tips-for-fitness-trainers">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previos blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent3;
