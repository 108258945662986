import NavBar from "../components/NavBar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Link as Scroll } from "react-scroll";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "../styling/AboutPage.scss";

import fredImg from "../assets/pltfrmImage6.png";
import img7 from "../assets/pltfrmImage7.jpeg";
import { Link } from "react-router-dom";


const AboutPage =()=>{

    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/aboutus") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <div className="aboutUsPage">
            <header className="aboutHeader" ref={elementRef}>
                <div className="wrapper">
                    <NavBar />
                </div>
                <div className="headerContent">
                    <div className="wrapper">
                        <div className="aboutUsHeader">
                            <h2>
                                A space for personal trainers and fitness
                                professionals to grow their fitness businesses
                                in Toronto
                            </h2>
                            <div className="buttonContainer">
                                <button>
                                    <Link to="/discover">
                                        Become a pltfrmPro
                                    </Link>
                                </button>

                                <button>
                                    <Link to="/form">Contact Us</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section className="aboutUs">
                <div className="wrapper">
                    <div className="aboutUsContainer">
                        <div className="aboutTitle">
                            <h2>About</h2>

                            <div className="logoName"></div>
                        </div>

                        <div className="aboutText">
                            <p>
                                Our mission is to empower personal trainers and
                                fitness professionals in Toronto to grow and run
                                their fitness businesses out of our downtown
                                location.
                            </p>
                            <p>
                                As fitness trainers we want you to be your own
                                boss, in charge of setting your own schedule,
                                and decide your own rates. What we provide is a
                                physical space with high-quality equipment to
                                train your clients in.
                            </p>

                            <p>
                                We promise you a premium, exclusive, spacious,
                                and well-designed space from which you can run
                                your business on your terms.
                            </p>

                            <h4>Independent. Empowered. Thriving.</h4>

                            <button className="resourceButton">
                                <Link to="/forfitnessprofessionals/resources">
                                    Resources for fitness professionals
                                </Link>
                            </button>
                        </div>
                    </div>

                    <div className="founderArrowContainer">
                        <div className="arrowContainerText">
                            <p>Meet the founder</p>
                        </div>

                        <div className="arrowHoverContainer">
                            <Scroll
                                to="founder"
                                smooth={true}
                                className="arrowIcon"
                            >
                                <FontAwesomeIcon icon={faArrowDown} />
                            </Scroll>
                        </div>
                    </div>
                </div>
            </section>

            <section className="founder" id="founder">
                <div className="wrapper">
                    <div className="founderText">
                        <p>
                            The idea for PLTFRM was born when Fred started
                            questioning the fact that so many fitness
                            professionals walked away from the industy after a
                            few years of working in it. Fred realized that
                            fitness professionals weren't making enough money to
                            make this a sustainable business. So many trainers
                            are tied to gyms that take big chunks from their
                            billings. A lot of trainers don't have the capital
                            to start a gym of their own.
                        </p>
                        <p>
                            PLTFRM is focused on letting fitness trainers be
                            their own bosses. Fred's lifelong love for fitness
                            and his entrepreneurial spirit is what gave him the
                            courage to follow through on his idea and make
                            something that was once a simple idea a reality.
                        </p>
                        <div className="buttonContainer">
                            <button>
                                <Link to="/discover">Become a pltfrmPro</Link>
                            </button>

                            <button>
                                <a href="mailto:fred@pltfrm.fit">
                                    Get in touch
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className="founderImg">
                        <img
                            src={fredImg}
                            alt="PLTFRM Founder Fred wearing a PLTFRM T-shirt"
                        />
                    </div>
                </div>
            </section>

            <section>
                <div className="wrapper">
                    <div className="visionContainer">
                        <img
                            src={img7}
                            alt="Two female personal trainers wearing PLTFRM T-shirts demonstrating hand stretches"
                        />
                        <div className="visionText">
                            <p>
                                Our vision is to provide personal trainers and
                                fitness professionals a space that has
                                everything they need to run their business out
                                of while also being independent business owners
                                themselves.
                            </p>
                            <button>
                                <Link to="/discover">Become a pltfrmPro</Link>
                            </button>

                            <p className="disclaimerText">
                                When you sign up today you will get first
                                priority at our *special* introductory rates.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AboutPage;