import React from 'react'
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const MembershipOptions = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/memberships/membershipOptions") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
  return (
      <>
          <div
              className="membershipOptionTable"
              id="memberships"
              ref={elementRef}
          >
              <div className="membershipOption">
                  <div className="optionInfo">
                      <div>
                          <h4>10 Session/Month</h4>
                      </div>
                      <h3>CAD 310 + HST</h3>
                  </div>
                  <ul className="membershipFeatures">
                      <li>10 Training Hours</li>
                      <li>24/7 Access to PLTFRM</li>
                      <li>Digital Profile on the PLTFRM website</li>
                      <li>Access to content creation equipment</li>
                  </ul>
                  <div className="offerText">
                      <p>Total Cost</p>
                      <h3>350.30/Month</h3>
                  </div>
              </div>
              <div className="membershipOption">
                  <div className="optionInfo">
                      <div>
                          <h4>20 Session/Month</h4>
                      </div>
                      <h3>CAD 580 + HST</h3>
                  </div>
                  <ul className="membershipFeatures">
                      <li>20 Training Hours</li>
                      <li>24/7 Access to PLTFRM</li>
                      <li>Digital Profile on the PLTFRM website</li>
                      <li>Access to content creation equipment</li>
                  </ul>
                  <div className="offerText">
                      <p>Total Cost</p>
                      <h3>655.40/Month</h3>
                  </div>
              </div>
              <div className="membershipOption optionHighlight">
                  <div className="optionInfo">
                      <div>
                          <h4>
                              30 Session/Month
                              <span>MOST Popular</span>
                          </h4>
                      </div>
                      <h3>CAD 750 + HST</h3>
                  </div>
                  <ul className="membershipFeatures">
                      <li>30 Training Hours</li>
                      <li>24/7 Access to PLTFRM</li>
                      <li>Digital Profile on the PLTFRM website</li>
                      <li>Access to content creation equipment</li>
                  </ul>
                  <div className="offerText">
                      <p>Total Cost</p>
                      <h3>847.50/Month</h3>
                  </div>
              </div>
              <div className="membershipOption fillerOption">
                  {/* blank option for styling*/}
              </div>
              <div className="membershipOption">
                  <div className="optionInfo">
                      <div>
                          <h4>Unlimited</h4>
                          <p>Per Month</p>
                      </div>
                      <h3>CAD 900 + HST</h3>
                  </div>
                  <ul className="membershipFeatures">
                      <li>Unlimited Training Hours</li>
                      <li>24/7 Access to PLTFRM</li>
                      <li>Digital Profile on the PLTFRM website</li>
                      <li>Access to content creation equipment</li>
                  </ul>
                  <div className="offerText">
                      <p>Total Cost</p>
                      <h3>1017/Month</h3>
                  </div>
              </div>
          </div>
      </>
  );
}

export default MembershipOptions