import blogImage1 from "../assets/blogAssets/blogPost16-img1.png";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ShareButtons from "../components/ShareButtons";

const BlogContent16 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/the-two-communities-every-personal-trainer-must-build"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>
                    "A Flexible Workday: How to make the most out of your
                    working hours."
                </title>

                <meta
                    name="description"
                    content="This week we dive into what life and work are like as an entrepreneur and why they  need to prioritize community. We also talk about what the two communities are that personal trainer needs to build for themselves in order to grow a successful business."
                />
            </Helmet>
            <h2 ref={elementRef}>
                How Important is Community for Personal Trainers?{" "}
            </h2>
            <h3 className="subTitleH3">A strategy to build a community</h3>
            <div className="authorContainer">
                <h4>April 20th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <p>
                Before we talk about community for personal trainers let’s take
                a step back and dig into the{" "}
                <strong>importance of community for entrepreneurs.</strong>
            </p>

            <p>
                As an entrepreneur, you are doing a lot of things on your own.
                You are building a business and doing a lot of it alone.
            </p>

            <p>Let’s be honest, this can get loney, scary, and overwhelming.</p>

            <p>
                If you are the only person in your immediate circle who is going
                the entrepreneurial route it's so normal to sometimes feel
                isolated. If any of this resonates with how you feel from time
                to time, know that you are not alone or wrong for feeling this
                way.
            </p>

            <p>
                As an entrepreneur a community can help you on the days you feel
                a little lonely. A community can also inspire and motivate you.
                A community is great to lean on when you have questions or need
                to bounce ideas off someone.
            </p>

            <p>
                As humans, we all need another human. That is a part of this
                existence. The need to feel included and supported comes from
                our very core.
            </p>

            <p>So, in short, the community is important for entrepreneurs.</p>

            <p>
                Community is important for personal trainers because you are in
                the business of building people.
            </p>

            <p>
                There are two communities that you will build as a personal
                trainer.
            </p>
            <img
                className="blogImg"
                src={blogImage1}
                alt="Infographic showing the two communities personal trainers need to build. "
            />
            <p>
                Part of the industry that you are a part of is forever changing
                which means you need to always be learning.
            </p>

            <p>
                This is where a community comes in. We are all on our own
                journey, but when you surrounded yourself who are on a similar
                path and of similar mindsets, you get to learn and grow
                alongside them.
            </p>

            <p>
                When you are in the business of people, testimonials will always
                be valuable. What your community says about you is important.
                Don’t ever underestimate this very simple marketing tactic.
            </p>

            <p>
                Now that we’ve talked about the why, let’s look at how you can
                start building a community for yourself.
            </p>

            <ol className="blogList numberedList">
                <li>
                    <h4>Create a Social Media Presence</h4>
                    <p>
                        This will allow you to communicate with existing clients
                        and reach potential clients who go beyond your
                        geographical location.
                    </p>
                </li>
                <li>
                    <h4>Host Group Workouts and Events</h4>
                    <p>
                        This is a great way to bring your clients together and
                        let them enjoy a sense of community while partaking in a
                        common activity.
                    </p>
                </li>
                <li>
                    <h4>Create a Private Online Group</h4>
                    <p>
                        This is could be a niched community. It allows your
                        community to communicate with you and one another.
                    </p>
                </li>
                <li>
                    <h4>Collaborate with Other Fitness Professionals</h4>
                    <p>
                        There are so many creative ways to do this. By working
                        with other professionals you can play into each other
                        strengths and create a network. This is also a great way
                        to keep learning.
                    </p>
                </li>
            </ol>

            <p>
                <strong>At PLTFRM,</strong> we are a gym in which you can host
                your client community. We are also dedicated to creating a
                network of other personal trainers for you to connect, grow, and
                learn with.
            </p>

            <p>
                Even though it can sometimes feel like the entrepreneurial path
                is lonely there are lots of things you can do to support
                yourself. Your part in all of this is to simply prioritize what
                is important to you.
            </p>

            <p>
                PLTFRM is getting ready for a soft launch. If you would like to
                be on our roaster of fitness professionals for our opening
                please connect with our team at{" "}
                <strong>
                    <a href="mailto: marketing@pltfrm.fit">
                        marketing@pltfrm.fit
                    </a>
                </strong>
            </p>

            <p>Stay tuned for tips and updates coming soon!</p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/the-business-side-of-client-acquisition">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/how-to-make-the-most-out-of-your-working-hours">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent16;
