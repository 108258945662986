import blogImage from "../assets/blogAssets/blogPost8-img1.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import ShareButtons from "../components/ShareButtons";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const BlogContent8 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/blog/blogPost/do-this-to-grow-your-business"){
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <>
            <Helmet>
                <title>
                    What Every Personal Trainer Should be Doing to Grow Their
                    Business
                </title>

                <meta
                    name="description"
                    content="Ready to take your business growth to the next level? In this post, we share the one thing you should be focusing on as a personal trainer to grow your business. Sweet, simple, and to the point: this blog post could change your business by helping you find your focus."
                />
            </Helmet>

            <h2 ref={elementRef}>
                What Every Personal Trainer Should be Doing to Grow Their
                Business
            </h2>

            <div className="authorContainer">
                <h4>February 14th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage}
                alt="Three female personal trainers sitting down and smiling. All wearing t-shirts with the PLTFRM logo"
            />

            <p>
                Growing any kind of service-based business is all about the
                ‘service’ you offer. If your offer is unique + valuable people
                will flock to you. If your service solves a problem that your
                audience has then you’ve mastered one of the key components of
                marketing. Marketing without a valuable product is a lot harder
                than marketing something that is top quality.
            </p>

            <p>
                As a fitness trainer, you need to continuously be asking
                yourself what problem your services solves and then you need to
                communicate that with your audience. In order to grow your
                business you need to make sure your customer: their needs and
                wants, are at the center of your business.
            </p>

            <h3>Focus On Communication In Your Content</h3>

            <p>
                Create content that is original and that clearly communicates
                the benefits and changes your services will bring to your
                client's life. Even if the benefits seem obvious to you as the
                trainer they might not be as obvious to a client.
            </p>
            <p>
                It’s part of your job to remind your clients over and over again
                of the value that you bring. It’s your job to show them how much
                easier their life would be if they had an expert on their side
                coaching them.
            </p>

            <p>
                You can never talk about your services too much for the right
                audience. Stop limiting yourself. Start communicating how your
                services could change their life for the better!
            </p>

            <h3>Focus On Customer Service</h3>

            <p>
                Providing excellent customer service is key for multiple
                reasons. They make clients feel heard and valued. Additionally,
                if one client has a positive experience they are more likely to
                share that experience with their social circle. The power of
                word-of-mouth marketing is still very much alive. It’s
                especially important for service-based businesses.{" "}
            </p>

            <p>
                As a fitness trainer and business owner it is your job to
                streamline the process for clients entering and exiting your
                world. The easier you make the process the less likely your
                clients are to second-guess their decision to work with you.
            </p>

            <h3>Conclusion</h3>

            <p>
                Growing your fitness training business takes effort. You need to
                make sure your strategies and support are in place. It’s your
                job to leverage the tools and platforms at your disposal. It’s
                also your job to be smart about how you grow your client base.
            </p>

            <p>
                At PLTFRM, we want to help you on this journey. We are excited
                to start building a community of Fitness Professionals here in
                Toronto to cheer each other on as we all build our businesses.
                You can sign up to become a{" "}
                <Link to="/forfitnessprofessionals">#PLTFRMPRO</Link> below and
                get in on our introductory.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/movement-and-longevity">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/the-journey-to-full-time-personal-trainer">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent8;
