import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost19-img1.png";

const BlogContent19 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/Emily-Dugan-on-women-and-fitness"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>
                    Emily Dugan ON: depression, strength training, infradian
                    cycles, and empowering women in the fitness world
                </title>

                <meta
                    name="description"
                    content="Emily Dugan is on a quest to empower women with knowledge of their cycles and how they can use it to inform their performance in the gym. Her vivacious energy is palpable and her passion for her work is admirable. In this article, Emily shares her personal journey and story and spills all the deets on the new brand she building."
                />
            </Helmet>

            <h2 ref={elementRef}>Emily Dugan ON:</h2>
            <h3 className="subTitleH3">
                depression, strength training, infradian cycles, and empowering
                women in the fitness world
            </h3>

            <div className="authorContainer">
                <h4>May 12, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage}
                alt="Background text about women and equality. The center text says women & fitness."
            />

            <p>
                The PLTFRM team recently sat down with Emily Dugan, aka{" "}
                <a href="https://www.instagram.com/_emilydugan/">
                    @_emilydugan
                </a>{" "}
                on Instagram, and had an eye-opening conversation about fitness
                and the female cycles. Emily is on a mission to empower women
                with knowledge when it comes to understanding, accepting, and
                learning about their bodies and cycles.
            </p>

            <p>
                Prior to the pandemic, Emily was a personal trainer in Toronto.
                During the pandemic, Emily moved her training online but didn’t
                find that she connected with clients as much. This led to her
                taking a slight break, eventually leading her to the work she
                does today.
            </p>

            <p className="blogQuoteText">
                <span className="tab"></span>
                “At the time my desire for change was fueled by the physical
                component. I had gained weight and was battling depression and I
                decided to start by controlling what I could control. I couldn’t
                control the job market so instead I’m going to start using my
                time in the gym.”
            </p>
            <p>
                We started our conversation going all the way to the beginning
                of Emily’s personal fitness journey. “My fitness journey started
                in a dark time in my life when I was trying to get all these
                corporate jobs that just weren’t working out. I wasn’t
                prioritizing myself, I had no sleep hygiene and a terrible
                diet.” Emily went on to talk about how in this dark place she
                had a moment of realization when she knew she had to make a
                change. “At the time my desire for change was fueled by the
                physical component. I had gained weight and was battling
                depression and I decided to start by controlling what I could
                control. I couldn’t control the job market so instead I’m going
                to start using my time in the gym.”
            </p>
            <p>
                Emily talked about how even though her goals at the start were
                based on her looks that quickly shifted to a focus on how
                working out made her feel. Emily shared, “When people around me
                started noticing the changes in me they would ask if I could
                create a program for them and that’s what led me to think that
                maybe I could make a career out of this for myself.”
            </p>
            <p className="blogQuoteText">
                <span className="tab"></span>
                “I want to shed light on how someone can train as a woman or as
                someone with a menstrual cycle.”
            </p>
            <p>
                Today, a big part of Emily’s new brand focus on women and
                strength training. This focus and passion have come from years
                of personal and professional experience. Emily believes, “Women
                are grossly underrepresented in the research done on fitness.
                The machines in the gym are not designed for women.” Emily went
                on to explain how women’s bodies run on, roughly, a 28-day
                infradian cycle, and this cycle impacts their strength training
                abilities. There are times in the month when a woman can lift
                heavy and times in a month when she needs to do something
                lighter. Emily shared how her new brand focus on this, “I want
                to shed light on how someone can train as a woman or as someone
                with a menstrual cycle.”
            </p>
            <p>
                Emily told our team how she wishes more women understood how
                they could work with their bodies instead of feeling like their
                bodies were working against them. She shared how the knowledge
                should start young, “boys and girls should learn about this way
                to view cycles whenever sex ed comes up in school. Boys' cycles
                and journey through puberty are often glorified while women are
                somewhat apologetic for theirs and receive sympathy. Girls are
                told they are going to be moody, have cramps, and that's just
                the way it is. That is not the way it has to be.” Emily shared
                how we need to start empowering girls with the knowledge that
                they can eat a certain way, train a certain way, and do other
                things that support their cycle.
            </p>
            <p className="blogQuoteText">
                <span className="tab"></span>
                "Girls are told they are going to be moody, have cramps, and
                that's just the way it is. That is not the way it has to be.”
            </p>
            <p>
                Our conversation with Emily was fascinating and we are so
                excited to watch the brand she is growing come to life. If you
                would like a sneak peek into our conversation with Emily head to
                our Instagram channel. If you would like to connect with Emily
                you can find her on Instagram and Tiktok @_emilydugan.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/exceptional-customer-service">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/a-conversation-with-KD-about-life-and-basketball">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent19;
