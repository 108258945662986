import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link as Scroll } from "react-scroll";

import NavBar from "../components/NavBar";
import "../styling/Memberships.scss"
import image1 from "../assets/pltfrmImage10.jpg"
import MembershipOptions from "../components/MembershipOptions";
import ActiveCampaignFormPopup from "../components/ActiveCampaignFormPopup";
import Specials from "../components/Specials";

function Memberships() {
    const location = useLocation();
    const elementRef = useRef(null);
    const [signUp, setSignUp] =useState(false)

    useEffect(
        function () {
            if (location.pathname === "/memberships") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
            setSignUp(false)
        },
        [location]
    );

    const handleClick = () => {
        setSignUp(!signUp);
    };
    return (
        <div className="membershipsPage">
            <header className="membershipsHeader" ref={elementRef}>
                <div className="wrapper">
                    <NavBar />
                </div>
                <div className="membershipsHeaderContent">
                    <div className="wrapper">
                        <div className="membershipsHeaderText">
                            <h1>Memberships</h1>
                            <p>
                                Our memberships are designed for personal trainers and fitness enthusiasts looking for a private gym facility in the heart of Downtown, Toronto.
                            </p>
                            <div className="buttonContainer">
                                <button>
                                    <a href="https://calendly.com/pltfrm-fit/30mintour">
                                        Book a Tour
                                    </a>
                                </button>

                                <button>
                                    <Scroll to="memberships" smooth={true}>
                                        Memberships
                                    </Scroll>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <Specials />
            <section>
                <div className="wrapper membershipsBannerText">
                    <h2>
                        All Members Get Complimentary Access To ALL PLTFRM
                        Facilities
                    </h2>
                </div>
            </section>

            <section className="featuresList">
                <div className="wrapper">
                    <img src={image1} alt="PLTFRM Downtown Toronto gym" />
                    <div className="featuresListContent">
                        <ul>
                            <li>Private/Semi-private gym spaces</li>
                            <li>Consultation rooms</li>
                            <li>Meeting room/Workspace</li>
                            <li>Content Creation Space</li>
                            <li>Marketing/Branding Services</li>
                            <li>Free Wifi</li>
                        </ul>

                        <button>
                            <a href="mailto:marketing@pltfrm.fit">
                                Connect with Our Team
                            </a>
                        </button>
                    </div>
                </div>
            </section>
            <section className="signUpToday membershipsSignUpToday">
                <div className="wrapper">
                    <h2 className="fitnessSectionTitle">
                        Become a
                        <span className="importantHeader"> PLTFRMPRO</span>{" "}
                        today
                    </h2>
                    <div className="startTodayList">
                        <p>
                            <span>Step 1:</span> Choose a Membership
                        </p>
                        <p>
                            <span>Step 2:</span> Book a date and Time
                        </p>
                        <p>
                            <span>Step 3:</span> Train & Repeat
                        </p>
                    </div>
                    <button>
                        <a href="https://www.wellnessliving.com/catalog/pltfrm">
                            Start Today
                        </a>
                    </button>
                </div>
            </section>

            <section>
                {signUp ? <ActiveCampaignFormPopup signUp={signUp} /> : <></>}
            </section>

            <section className="membershipSection">
                <div className="wrapper">
                    <h2>
                        Our Memberships Gives You Access to{" "}
                        <span>Private/Semi-Private Gym Spaces</span>
                    </h2>
                    <p>
                        You can pick a membership based on the number of
                        training hours you need. Our studio is open{" "}
                        <span>24/7</span> and all complimentary facilities are
                        always available to members.
                    </p>

                    <p>1 Training hour = 1 hour</p>

                    <MembershipOptions />
                </div>
            </section>
            <section className="membershipSingleSession">
                <div className="wrapper">
                    <h3>Single Session</h3>
                    <h2>
                        CAD <span>37 + HST</span>
                    </h2>
                    <button>
                        <a href="https://www.wellnessliving.com/catalog/pltfrm">
                            Join now
                        </a>
                    </button>
                    <p className="disclaimerText">
                        Can't find a membership that fits your business needs?
                        Get in touch with us for custom membership options.
                    </p>
                </div>
            </section>

            <section className="membershipBookCall">
                <div className="wrapper">
                    <div>
                        <h2>Book a Tour</h2>

                        <p>
                            Want to learn more about PLTFRM? Come see what we
                            have to offer for yourself, learn more about
                            facilities, and how PLTFRM can help you grow your
                            business!
                        </p>

                        <button>
                            <a href="https://calendly.com/pltfrm-fit/30mintour">
                                Book Now
                            </a>
                        </button>
                    </div>
                </div>
            </section>
            <section className="membershipsJoinEmail">
                <div className="wrapper">
                    <h2>Join our Email List</h2>
                    <p>
                        Sign up for our email list and join an exclusive
                        community of personal trainers in Toronto. You will get
                        access to our private slack channel to network and build
                        connections. You will also get exclusive access to
                        PLTFRM offers and news.
                    </p>
                    <button onClick={handleClick}>Sign up</button>
                </div>
            </section>
        </div>
    );
}

export default Memberships;
