import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost24-img1.png";
import blogImage2 from "../assets/blogAssets/blogPost24-img2.png";

const BlogContent24 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/social-media-for-personal-trainers"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>Social Media for Personal Trainers</title>

                <meta
                    name="description"
                    content="We’re getting into a not so popular social platform for personal trainers: Linkedln. We go through why and how Linkedln can help you grow your personal training business and teach you everything you need to know to optimize your Linkedln profile to get started."
                />
            </Helmet>
            <h2 ref={elementRef}>Social Media for Personal Trainers</h2>
            <h3 className="subTitleH3">
                Are you missing out by not building a Linkedln Presence?
            </h3>
            <div className="authorContainer">
                <h4>June 26, 2023</h4>
                <p>by PLTFRM</p>
            </div>
            <img
                className="blogImg"
                src={blogImage}
                alt="Person typing on a laptop with the PLTFRM logo"
            />
            <p>
                So, we all know personal trainers need to have an online
                presence. If you want to start or grow your business being on
                social media and actively engaging and building a community is
                an essential skill to have. The most popular platform for
                fitness professionals are Facebook, Instagram, and TikTok. These
                platforms are fun, they are engaging, they are aesthetic. So if
                you’ve got a community or any of these, or maybe all of them,
                you are probably feeling pretty good about yourself and your
                business.
            </p>
            <p>
                Today, we are going to talk about if Linkedln could be a
                possible platform for you as well.
            </p>
            <p>
                Linkedln is often thought of as a platform for{" "}
                <strong>traditional business oriented people</strong>.
            </p>
            <p>
                It’s a by the book platform for people to network, find jobs, or
                have business-related discussions.
            </p>
            <p>
                Here’s the thing, Linkedin is beneficial for individuals in
                various industries, including personal trainers and those in the
                fitness and wellness fields.
            </p>
            <p>
                At the end of the day, Linkedin is a social media platform and
                it’s high time we start using it as such. If you are dedicated
                to growing and expanding your business Linkedin might be an
                option for you.
            </p>
            <p>
                Personal trainers can leverage Linkedln to connect with
                potential clients, industry peers, fitness facility owners,
                nutritionists, physical therapists, and other professionals in
                the health and wellness spaces.
            </p>
            <p>
                Look at this as an opportunity to showcase your expertise, share
                valuable content, and engage in relevant discussions. All you
                need to do is learn how to utilize Linkedln{" "}
                <strong>effectively</strong>.
            </p>
            <img
                className="blogImgType2 infoGraphic2"
                src={blogImage2}
                alt="Infographic how to to create an engaging summary on Linkedln "
            />
            <h3>
                Here are some effective ways personal trainers can utilize
                Linkedln for networking
            </h3>
            <ol className="blogList numberedList">
                <li>
                    <h4>Optimize Your Linkedln Profile</h4>
                    <p>
                        This includes creating a professional and engaging
                        profile that highlight your expertise, qualifications,
                        and experience as a personal trainer. Make sure you have
                        a completing headline and a well-written summary.
                    </p>
                </li>
                <li>
                    <h4>Connect with Industry Professionals</h4>
                    <p>
                        Search and connect with people in the fitness industry.
                        Don’t just limit yourself to your geographic location.
                        The whole point of social media is you can connect with
                        people anywhere. You never know what doors might open.
                    </p>
                </li>
                <li>
                    <h4>Engage in Fitness and Health Groups</h4>
                    <p>
                        Join Linkedln groups related to fitness, health, and
                        personal training. These are great places to to
                        participate in discussions, share valuable insights, and
                        connect with individuals with similar interests.
                    </p>
                </li>
                <li>
                    <h4>Publish Valuable Content</h4>
                    <p>
                        Again, Linkedln is a social media platform. Use it as
                        one. Share informative and educational content related
                        to fitness, workouts, nutrition, and wellness. This
                        positions you as a thought leader and attracts
                        engagement from potential clients and industry
                        professionals.
                    </p>
                </li>
                <li>
                    <h4>Seek Recommendations</h4>
                    <p>
                        Ask satisfied clients and colleagues to leave
                        recommendations on your profile. This will help build
                        your credibility and influence potential clients and
                        employers.
                    </p>
                </li>
                <li>
                    <h4>Engage with Your Network</h4>
                    <p>
                        Like all social media platforms, being ‘social’ is
                        important. Regularly engage with your connections by
                        liking, commenting, and sharing their posts.
                    </p>
                </li>
            </ol>
            <p>
                Remember, to regularly update your Linkedln profile and stary
                active. Linkedln has the potential to help attract potential
                clients, forge valuable connection and elevate your personal
                training business is you use it currently. Are you ready to
                commit to growing your business?
            </p>

            <ShareButtons url={location.pathname} />
           
            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/ola-on-body-image-fitness-goals-and-how-to-pick-a-trainer">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/the-emotionally-intelligent-personal-trainer">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent24;
