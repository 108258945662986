import React, {useEffect, useRef} from 'react'
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTiktok,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";
import quoteImg from "../../assets/trainerAssests/quotes.png"
import video from "../../assets/trainerAssests/kareem/kareemVideo.MP4"
import { useLocation } from "react-router-dom";

function Kareem() {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/ourtrainers/trainer/Kareem-Whyte") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
  return (
      <>
          <header className="trainerHeader kareemHeader" ref={elementRef}>
              <div className="wrapper">
                  <div className="trainerHeaderText">
                      <h1>Meet Kareem</h1>
                      <p>A PLTFRMPRO Trainer</p>
                      <button className="trainerContactButton">
                          <Link to="/workoutform">Contact Kareem</Link>
                      </button>
                  </div>
              </div>
          </header>
          <Helmet>
              <title>FITNESS TRAINER IN TORONTO | PLTFRM | KAREEM WHYTE</title>

              <meta
                  name="description"
                  content="Kareem focuses on strength training, conditioning, and bodybuilding. He is a result-based personal trainer at a premium downtown Toronto gym."
              />
          </Helmet>

          <section className="getToKnow">
              <div className="wrapper">
                  <h2>Get to Know Kareem...</h2>
                  <div className="trainerBio">
                      <img
                          className="trainerQuotes"
                          src={quoteImg}
                          alt="quotes"
                      />
                      <div className="bioText">
                          <p>
                              I'm from Toronto, Ontario and I am of Jamaican
                              descent. I grew up in the Rexdale area of northern
                              Etobicoke. I was always athletic and would
                              frequently be found playing basketball on any
                              court I could find. I was also always one of the
                              smallest people on the court, which was the main
                              catalyst for me to get into fitness. I played
                              bigger than my size, but I was often overmatched
                              because I just didn't have the size. That led me
                              down the road of bodybuilding and getting "gains"
                              in the gym.
                          </p>
                          <p>
                              After experiencing firsthand all the wrong and
                              right ways to bulk up and trim down, and realizing
                              that I can effect great change through fitness, I
                              decided to become a fitness coach. Today I aspire
                              to help people transform their bodies and do it in
                              the healthy and most effective way possible.
                          </p>
                      </div>
                  </div>
              </div>
          </section>

          <section className="trainerVideo">
              <div className="wrapper">
                  <video
                      src={video}
                      playsInline
                      preload="auto"
                      controls
                      type="video/mp4"
                      id="blogVideo"
                      title="Kareem Whyte"
                  ></video>
              </div>
          </section>

          <section className="qualifications">
              <div className="wrapper">
                  <h2>Qualifications</h2>
                  <p>CanFitPro PTS Certification</p>
                  <p>DTS LVL 1</p>
                  <p>DTS Conditioning Co</p>
              </div>
          </section>
          <section className="quotes">
              <div className="wrapper">
                  <div className="quoteContainer quote1">
                      <h3>#1 Training Advice for People</h3>
                      <p>
                          “Be honest with yourself about your current status so
                          that you can truly transform.”
                      </p>
                  </div>
                  <div className="quoteContainer quote2">
                      <h3>What do you love about fitness?</h3>
                      <p>
                          “I love that there is no ceiling to how much one can
                          learn about the theory of fitness and how much fitness
                          can teach one about themselves. It's the ultimate
                          marker for where you are physically and mentally in
                          any stage of life.”
                      </p>
                  </div>
              </div>
          </section>
          <section className="trainerSocials">
              <div className="wrapper">
                  <h2 className='h2FormLink'>
                      <Link to="/workoutform">Contact Kareem</Link>
                  </h2>
                  <p>kareem.templebuilders@gmail.com</p>
                  <div className="trainerSocialsContainer">
                      <a
                          href="https://www.instagram.com/trainwithcoachk/"
                          className="iconCircle"
                      >
                          <FontAwesomeIcon
                              icon={faInstagram}
                              className="fa-fw instagramIcon"
                          />
                          <span className="sr-only">Instagram Icon</span>
                      </a>
                      <a
                          href="https://www.tiktok.com/@trainwith_coachk?_t=8jFtn9uG7kc&_r=1"
                          className="iconCircle"
                      >
                          <FontAwesomeIcon
                              icon={faTiktok}
                              className="fa-fw tiktokIcon"
                          />
                          <span className="sr-only">Tiktok Icon</span>
                      </a>
                  </div>
              </div>
          </section>
      </>
  );
}

export default Kareem