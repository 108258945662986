import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import ShareButtons from "../components/ShareButtons";

import blogImage from "../assets/blogAssets/blogPost10-img1.png";

const BlogContent10 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/blog/blogPost/the-role-of-social-media") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <>
            <Helmet>
                <title>
                    The Role of Social Media In Building a Successful Personal
                    Training Business
                </title>

                <meta
                    name="description"
                    content="Social media plays a crucial role in building a successful personal training business. In this blog post we take you through some of the ways social media can help you start or expand your business."
                />
            </Helmet>

            <h2 ref={elementRef}>
                The Role of Social Media In Building a Successful Personal
                Training Business
            </h2>

            <div className="authorContainer">
                <h4>March 7, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage}
                alt="hand holding a phone with social icons and a the PLTFRM logo in the left
corner"
            />

            <p>
                Social media is a big part of our everyday lives. Social media
                is how we communicate, it’s where we get our news from, and it’s
                how we stay connected to the world. Over the last decade, social
                media has become a powerful tool for businesses and brands.
            </p>

            <p>
                It’s no secret that social media has dramatically influenced the
                fitness industry. It lets personal trainers expand their reach
                beyond their local community. In this blog post, we will explore
                how social media can help you, as a personal trainer, build a
                thriving business.
            </p>
            <h3>Brand Awareness</h3>
            <p>
                Instagram and TikTok are two of the leading social media
                platforms that personal trainers use to spread awareness about
                their brands. With social media, as a personal trainer, you get
                to be a brand all by yourself. YOU are the face of your
                business. Through a strategic approach focused on quality
                content and engagement, you can attract potential clients who
                are interested in your services.
            </p>

            <h3>Online Coaching</h3>

            <p>
                With the rise of social media, we saw an increase in demand for
                online coaching. You can now use social media to deliver
                personalized training plans. More and more people want to be
                able to get fit from the comfort of their own homes. As a
                personal trainer, this has increased the flexibility you have in
                what you offer your clients.
            </p>

            <h3>Showcase Your Expertise</h3>

            <p>
                Social media is a great tool through which you can position
                yourself as an expert. You get to share your knowledge through
                content such as workout routines, nutrition tips, and
                motivational messages. When people view you as an expert,
                leader, or source of information they are more likely to keep
                coming back to you for more.
            </p>

            <p>
                Social media plays a crucial part in building a successful
                business. If used effectively, it can expand your reach, engage
                your clients, and help foster long-term relationships with your
                audience.
            </p>

            <p>
                If you are just getting into personal training or looking to
                grow your business check out our{" "}
                <Link to="/forfitnessprofessionals/workbook" class="linkBlock">
                    101 Personal Training Workbook
                </Link>{" "}
                for everything you need to build your brand and business.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/fitness-as-a-lifestyle">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/movement-and-longevity">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent10;
