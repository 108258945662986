import "../styling/TrainerProfileTemplate.scss";
import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";

import React from 'react'


function TrainerProfileTemplate() {
  return (
      <div className="trainerTemplateHeader">
          <div className="wrapper">
              <NavBar />
          </div>
          <section>
              <Outlet />
          </section>
      </div>
  );
}

export default TrainerProfileTemplate