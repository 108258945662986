import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost23-img1.png";
import blogImage2 from "../assets/blogAssets/blogPost23-img2.png";

const BlogContent23 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/the-emotionally-intelligent-personal-trainer"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>The Emotionally Intelligent Personal Trainer</title>

                <meta
                    name="description"
                    content="Emotional intelligence is important for personal trainers for their own lives and for their business. In this article we discuss why personal trainers need to prioritize emotional intelligence and the ways it can help you be a better trainer and business owner.
"
                />
            </Helmet>
            <h2 ref={elementRef}>
                The Emotionally Intelligent Personal Trainer
            </h2>
            <h3 className="subTitleH3">
                Growing your business through self-care
            </h3>
            <div className="authorContainer">
                <h4>June 20, 2023</h4>
                <p>by PLTFRM</p>
            </div>
            <img
                className="blogImg"
                src={blogImage}
                alt="PLTFRM Logo with the affirmation ‘I can do this’ 3 times"
            />
            <p>
                Fitness is not solely about physical health; it also impacts{" "}
                <strong>mental</strong> and{" "}
                <strong>emotional well-being</strong>. As a personal trainer
                taking the holistic aspect of your clients life into
                consideration is important not just for your clients but also
                for you.
            </p>
            <p>
                When you consider your client holistically the service you offer
                will be able to give them results that they feel past the
                physically body. Your clients results are important for your
                business. You want them to reach their goals. You want them to
                feel good in they bodies and mind. You want all this for them
                because you know this is when they will see the holistic value
                of your service.
            </p>
            <p>
                When you are in the business of providing a service, for your
                business to continue to grow and succeed you need that service
                to be one of value!
            </p>
            <p>
                So, to be able to provide this holistic service, you, as a
                personal trainer need to be emotionally aware and conscious of
                yourself.
            </p>
            <p>
                An emotionally intelligent personal trainer is the{" "}
                <strong>trainer of the future</strong>. This is a trainer that
                knows and embodies a healthy lifestlye holistically. You showing
                up as this person is what is going to inspire your clients and
                instil confidence. If they see you living what you preach the
                value of what you offer automatically goes up.
            </p>
            <img
                className="blogImgType2 infoGraphic2"
                src={blogImage2}
                alt="Infographic listing ways to build emotional intelligence for personal trainer by PLTFRM"
            />
            <h3>
                To run a successful personal training business today the best
                thing can do for yourself is have direction.
            </h3>
            <ol className="blogList numberedList">
                <li>
                    <h4>Self Awareness</h4>
                    <p>
                        It’s important your understand your own emotions,
                        triggers, strengths, and weaknesses. This will help you
                        better manage your emotion and reaction in different
                        situations.
                    </p>
                </li>
                <li>
                    <h4>Empathy</h4>
                    <p>
                        This is a crucial skills for a personal trainer to be
                        able to connect with their clients. How you empathise as
                        a trainer will directly effect the energetic environment
                        you create for your clients.
                    </p>
                </li>
                <li>
                    <h4>Active Listening</h4>
                    <p>
                        This is a skill you learn with practice. Your clients
                        come to you not just to learn how to physically do
                        something but they also want to feel heard and
                        respected. Your clients are humans with fears, concerns,
                        and feelings and you need to be able to appropriately
                        and proactively respond to them.
                    </p>
                </li>
                <li>
                    <h4>Emotional Regulation</h4>
                    <p>
                        As a personal trainer you must know how to regulate your
                        own emotions, especially in stressful situations. Being
                        calm, composed, and professional wil help you create a
                        safe space for clients and prevent any negative impact
                        on the training experience.
                    </p>
                </li>
                <li>
                    <h4>Relationship Building</h4>
                    <p>
                        You should be focusing on building strong authentic
                        relationships with your clients and community. You are
                        in the business of people and being able to build
                        relationships with the people in your world will be
                        important for your business to grow.
                    </p>
                </li>
                <li>
                    <h4>Continuous Learning and Development</h4>
                    <p>
                        Emotional intelligence is a skill you constantly improve
                        and build on over time. Personal trainers should
                        actively be pursuing opportunities for personal growth.
                        You must understand the value of this work and then keep
                        prioritizing it even when it is not easy or simple.
                    </p>
                </li>
            </ol>
            <p>
                If you are trying to grow your personal training business the
                more you take care of yourself and practice emotional
                intelligence the better you will be able to run your business.
            </p>
            <p>
                Emotional intelligence will not only elevate your life and your
                personal experiences but it will transform how you approach your
                business, your clients, and situations: this is what will help{" "}
                <strong>grow your business</strong>.
            </p>
            <p>
                If you are personal trainer in Toronto and are interested in
                learning more about PLTFRM,{" "}
                <a href="https://calendly.com/pltfrm/30min?month=2023-06">
                    book a virtual call
                </a>{" "}
                with our founder Fred. We can share more about what PLTFRM is
                and about how we can help you grow your business.
            </p>
            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/social-media-for-personal-trainers">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/are-there-too-many-personal-trainers">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent23;
