import React from 'react';
import Form from '../components/Form';
import Discover from '../components/Discover';
import NavBar from "../components/NavBar";
import Specials from '../components/Specials';

import { Link as Scroll } from "react-scroll";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

//Hero Image
import video from "../assets/heroImages/pltfrmHero.mp4";

//Graphics
import weight from "../assets/graphics/PLTFRM_Graphic1_weight.png"
import camera from "../assets/graphics/PLTFRM_Graphic2_camera.png";
import showerhead from "../assets/graphics/PLTFRM_Graphic3_showerhead.png";
import laptop from "../assets/graphics/PLTFRM_Graphic4_laptop.png";
import wifi from "../assets/graphics/PLTFRM_Graphic5_wifi.png";
import onlineBooking from "../assets/graphics/PLTFRM_Graphic6_onlinebooking.png";
import people from "../assets/graphics/PLTFRM_Graphic7.png";
import clock from "../assets/graphics/PLTFRM_Graphic8_clock.png";

//FacilityCarousel Images
import image1 from "../assets/facilityImages/PLTFRM_ourfacility_1.png"
import image2 from "../assets/facilityImages/PLTFRM_ourfacility_2.png";
import image3 from "../assets/facilityImages/PLTFRM_ourfacility_3.png";
import image4 from "../assets/facilityImages/PLTFRM_ourfacility_4.png";
import image5 from "../assets/facilityImages/PLTFRM_ourfacility_5.png";
import image6 from "../assets/facilityImages/PLTFRM_ourfacility_6.png";

//Styles
import "../styling/Header.scss";
import "../styling/HomePage.scss"

const HomePage = ()=>{
    const images =[image1, image2, image3, image4, image5, image6]
    
    function previousImage(){
        let slider = document.getElementById("slider")
        slider.scrollLeft = slider.scrollLeft - 400;
    }

    function nextImage() {
        let slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft + 400;
    }

    return (
        <>
            <header className="mainHeader">
                <video
                    src={video}
                    autoPlay
                    muted
                    loop
                    playsInline
                    type="video/mp4"
                    id="myVideo"
                ></video>
                <div className="wrapper">
                    <NavBar />

                    <div className="title">
                        <h1>
                            Helping <span className="titleImportant">you</span>{" "}
                            build <span className="titleImportant">your</span>{" "}
                            business
                        </h1>
                        <h2>
                            Bringing you a premium space for independent fitness
                            professionals in Toronto
                        </h2>
                    </div>

                    <div className="arrowContainer">
                        <div className="arrowContainerText">
                            <p>Discover how</p>
                            <div className="logoName"></div>
                            <p>works</p>
                        </div>
                        <div className="arrowHoverContainer">
                            <Scroll
                                to="discover"
                                smooth={true}
                                className="arrowIcon"
                            >
                                <FontAwesomeIcon icon={faArrowDown} />
                            </Scroll>
                        </div>
                    </div>
                </div>
            </header>
            <Specials />

            <Discover />

            <section className="aboutSection" id="about">
                <div className="wrapper">
                    <div className="aboutHeading">
                        <div className="logoName"></div>
                        <h3>
                            Is dedicated to empowering{" "}
                            <span className="titleImportant">
                                fitness professionals
                            </span>
                        </h3>
                    </div>
                    <ul>
                        <li>
                            <div className="shortLogo"></div>
                            <p>
                                We believe fitness professionals should be their
                                own bosses.
                            </p>
                        </li>
                        <li>
                            <div className="shortLogo"></div>
                            <p>
                                We are providing you with a space that has
                                high-quality equipment and is visually appealing
                                to train your clients in.
                            </p>
                        </li>
                        <li>
                            <div className="shortLogo"></div>
                            <p>
                                We offer you full autonomy to run your business
                                in a way that feels right for you.
                            </p>
                        </li>
                        <li>
                            <div className="shortLogo"></div>
                            <p>
                                Our downtown location makes it easy for your
                                clients to meet you and for new clients to find
                                you.
                            </p>
                        </li>
                        <li>
                            <div className="shortLogo"></div>
                            <p>
                                All you have to do is book a space at PLTFRM and
                                you will no longer have to share your revenue
                                with an employer.
                            </p>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="bookACall">
                <div className="wrapper">
                    <div>
                        <h2>Want to learn more?</h2>

                        <p>
                            Book a tour with a member of our team to learn about
                            everything that makes PLTFRM the space for you to
                            grow your business in.
                        </p>

                        <button>
                            <a href="https://calendly.com/pltfrm-fit/30mintour?month=2024-09">
                                Book a Tour
                            </a>
                        </button>
                    </div>
                </div>
            </section>

            <section className="facilityFeaturesSection">
                <div className="wrapper">
                    <h2>
                        Private Training & Gym &{" "}
                        <span className="titleImportant">
                            Fitness Coworking Space
                        </span>
                    </h2>
                    <p>
                        PLTFRM is located at 577 Wellington St W in the heart of Toronto’s downtown King West area making it an easy choice for fitness professionals and their clients. We are easily accessible by TTC and offer limited onsite parking with nearby public parking options. This is Toronto’s newest hotspot for fitness professionals ready to grow their business.
                    </p>
                    <ul className="facilityFeatureList">
                        <li>
                            <img
                                src={weight}
                                alt="weight icon"
                                className="graphic"
                            />
                            <h3>Top Quality Equipment</h3>
                        </li>
                        <li>
                            <img
                                src={camera}
                                alt="camera icon"
                                className="graphic"
                            />
                            <h3>Content & Production Space</h3>
                        </li>
                        <li>
                            <img
                                src={showerhead}
                                alt="Showerhead icon"
                                className="graphic"
                            />
                            <h3>Showers & Changing Rooms</h3>
                        </li>
                        <li>
                            <img
                                src={laptop}
                                alt="Laptop Icon"
                                className="graphic"
                            />
                            <h3>Consultation & Coworking Rooms</h3>
                        </li>
                        <li>
                            <img
                                src={wifi}
                                alt="Wifi icon"
                                className="graphic"
                            />
                            <h3>Free Wifi</h3>
                        </li>
                        <li>
                            <img
                                src={onlineBooking}
                                alt="Online booking icon"
                                className="graphic"
                            />
                            <h3>Online Booking & Payments</h3>
                        </li>
                        <li>
                            <img
                                src={clock}
                                alt="clock icon"
                                className="graphic"
                            />
                            <h3>Open 24/7</h3>
                        </li>
                        <li>
                            <img
                                src={people}
                                alt="People hugging icon"
                                className="graphic"
                            />
                            <h3>
                                Exclusive Access to a Growing Fitness Community
                            </h3>
                        </li>
                    </ul>
                    <button>
                        <Link to="/memberships">Join PLTFRM</Link>
                    </button>
                </div>
            </section>

            <section className="carouselSection">
                <div className="wrapper">
                    <h2>Our Facility</h2>
                    <div className="facilityCarousel">
                        <button
                            className="carouselButton buttonPrevious"
                            onClick={previousImage}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <div className="carouselImage" id="slider">
                            {images.map((image, index) => (
                                <img
                                    src={image}
                                    alt=""
                                    key={index}
                                    className={
                                        index === images.length - 1
                                            ? "slide lastSlide"
                                            : "slide"
                                    }
                                />
                            ))}
                        </div>
                        <button
                            className="carouselButton buttonNext"
                            onClick={nextImage}
                        >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </div>
            </section>
            <Form />
        </>
    );
}
export default HomePage