import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DiscoverContent from "../components/DiscoverContent";
import { Link as Scroll } from "react-scroll";

const Discover = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/discover") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
  return (
      <section ref={elementRef} className="discoverSection" id="discover">
          <div className="wrapper">
              <div className="discoverContent">
                  <div className="signUpText">
                      <h3>
                        Private 24/7 Gym space for Trainers and Fitness Enthusiasts
                      </h3>
                      <p>
                          We offer the most competitive rates in Downtown, Toronto
                      </p>
                      <button>
                          <Link to="/memberships/membershipOptions">
                              Our Memberships
                          </Link>
                      </button>
                  </div>
                  <DiscoverContent />
              </div>

              <div className="arrowContainer">
                  <div className="arrowContainerText">
                      <p>Why Choose</p>
                      <div className="logoName"></div>
                      <p>?</p>
                  </div>

                  <div className="arrowHoverContainer">
                      <Scroll to="about" smooth={true} className="arrowIcon">
                          <FontAwesomeIcon icon={faArrowDown} />
                      </Scroll>
                  </div>
              </div>
          </div>
      </section>
  );
}

export default Discover
