import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styling/WorkBook.scss"

import NavBar from "../components/NavBar";
import ActiveCampaignWorkBook from "../components/ActiveCampaignWorkBook";
import ActiveCampaignQuestion from "../components/ActiveCampaignQuestion";

import pltfrmImage8 from "../assets/pltfrmImage8.png";
import pltfrmImage9 from "../assets/pltfrmImage9.png";
import blog9Thumb from "../assets/blogAssets/blogThumbnails/blogPost9-thumbnail.png";
import blog8Thumb from "../assets/blogAssets/blogThumbnails/blogPost8-thumbnail.png";

const Workbook = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    const [download, setDownload] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(
        function () {
            if (location.pathname === "/forfitnessprofessionals/workbook") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
            setDownload(false);
            setHasLoaded(false)
           
        },
        [location]
    );

    const handleClick = () => {
        setDownload(!download);
    };

    return (
        <div className="workbookPage" ref={elementRef}>
            <header className="workbookHeader">
                <div className="wrapper">
                    <NavBar />
                </div>
                <div className="workbookHeaderContent">
                    <div className="wrapper">
                        <div className="workbookHeaderContainer">
                            <h1>101 Personal Training Workbook</h1>
                            <h3>For Fitness Trainers in Canada</h3>

                            <div className="buttonContainer">
                                <button onClick={handleClick}>
                                    download now
                                </button>

                                <button>
                                    <Link to="/form">Become a PLTFRMPRO</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section className="contentGuideBannerText">
                <div className="wrapper">
                    {download ? (
                        <ActiveCampaignWorkBook download={download} />
                    ) : (
                        <></>
                    )}
                </div>
            </section>

            <section className="wrapper workbookBanner">
                <h3>
                    A Workbook to <span className="importantText">Start</span> &{" "}
                    <span className="importantText">Grow</span> your personal
                    training business
                </h3>
            </section>

            <section className="businessOffTheGround">
                <div className="wrapper">
                    <h2>Let Us Help You Get Your Business off the Ground</h2>
                    <p>
                        Instead of spending hours and hours trying to gather
                        information about setting up and running a personal
                        business you can access everything you need in one
                        place.
                    </p>

                    <p>
                        This workbook is designed to get you thinking about what
                        kind of personal trainer you want to be, how you want to
                        set up the business, and share the steps you need to take
                        to make sure you are protected and legitimate.
                    </p>

                    <button onClick={handleClick}>download now</button>
                </div>
            </section>

            <section className="growYourBusinessContainer">
                <div className="wrapper">
                    <h3>
                        Grow <span className="importantText">Your</span>{" "}
                        Business <span className="importantText">Today!</span>
                    </h3>
                    <div className="imgContainer">
                        <img src={pltfrmImage9} alt="" />
                        <div className="yourBusinessTextContainer1">
                            <h4>Part 1</h4>
                            <p>
                                Everything you need to know about certifications
                            </p>
                        </div>
                    </div>
                    <div className="imgContainer">
                        <div className="yourBusinessTextContainer2">
                            <h4>Part 2</h4>
                            <p>
                                Everything you need to know about setting up
                                your business.
                            </p>
                        </div>
                        <img src={pltfrmImage8} alt="" />
                    </div>
                    <p>This workbook is completely FREE</p>
                    <button onClick={handleClick}>download now</button>
                </div>
            </section>

            <section className="signUpToday signUpWorkBook">
                <div className="wrapper">
                    <h2 className="fitnessSectionTitle">
                        Become a
                        <span className="importantHeader"> PLTFRMPRO</span>{" "}
                        today
                    </h2>
                    <p>
                        When you sign up you will still be an independent. You
                        will simply get access to our rates and be able to book
                        spaces and time slots to use PLTFRM's facilities.
                    </p>

                    <button>
                        <Link to="/form">Sign up</Link>
                    </button>

                    <p className="disclaimerText">
                        When you sign up today you will get first priority at
                        our *SPECIAL* introductory rates.
                    </p>
                </div>
            </section>

            <section className="contentBlogSection">
                <div className="wrapper">
                    <h3>
                        Follow our blog for
                        <span className="importantText"> Fitness Trainers</span>
                    </h3>
                    <div className="blogFlexContainer">
                        <div className="blogContainer">
                            <img
                                src={blog8Thumb}
                                alt="4 ways to grow your fitness business on social media"
                            />
                            <p>
                                What Every Personal Trainer Should be Doing to
                                Grow Their Business
                            </p>
                            <button>
                                <Link to="/blog/blogPost/do-this-to-grow-your-business">
                                    read more
                                </Link>
                            </button>
                        </div>

                        <div className="blogContainer">
                            <img src={blog9Thumb} alt="" />
                            <p className="canfitproText">
                                A Conversation about Movement and Longevity with
                                Joanne Lam
                            </p>
                            <button>
                                <Link to="/blog/blogPost/movement-and-longevity">
                                    read more
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="activeQuestionContainer">
                <div className="wrapper">
                    <ActiveCampaignQuestion form={hasLoaded} />
                </div>
            </section>
        </div>
    );
};

export default Workbook;
