import blogImage from "../assets/blogAssets/blogPost13-img1.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import ShareButtons from "../components/ShareButtons";


import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const BlogContent13 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname === "/blog/blogPost/you-are-never-too-young-to-follow-your-passions"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <>
            <Helmet>
                <title>
                    You Are Never Too Young To Follow Your Passions with Bahar
                    Sabour
                </title>

                <meta
                    name="description"
                    content="If you struggle to motivate yourself you need to read this article. Bahar Sabour sat down with our team and shared how she started her career as a personal trainer at a young age. Bahar’s vibrant and youthful energy is magnetizing. We thoroughly enjoyed our conversation with her and the wisdom she had to share"
                />
            </Helmet>

            <h2 ref={elementRef}>
                You Are Never Too Young To Follow Your Passions with Bahar
                Sabour
            </h2>

            <div className="authorContainer">
                <h4>March 28th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage}
                alt="Bahar Sabour with PLTFRM logo in the right corner"
            />

            <p>
                Over the weekend the PLTFRM team had the pleasure to sit down
                with Bahar Sabour, aka{" "}
                <a href="https://www.instagram.com/yourprobody/">
                    @yourprobody
                </a>{" "}
                on Instagram, and talk about her life and career as a personal
                trainer. Bahar has a wonderfully positive attitude and demeanor
                that we thoroughly enjoyed. We are so excited to introduce her
                and share her inspiring story with you.
            </p>

            <p>
                Bahar has been a full-time personal trainer for the last 5
                years. She began her career in Montreal and moved to Toronto in
                September 2019. Bahar shared how growing up her parents very
                much wanted her and her sister to have a well-rounded education
                and experiences. Bahar’s sister had always been very athletic
                from the very start while Bahar herself was uninterested in team
                sports, dance, or anything that her sister was interested in.
                Bahar also shared how physically different she and her sister
                were. “We had completely different builds and physiques. When
                you have different body types, you end up being good at
                different things and that is what happened with us. When I was
                younger I never understood why I never liked the things she
                liked.”
            </p>

            <p>
                Around the age of 14, Bahar started going to the gym and
                learning about weights. Bahar shared, “this was the first time
                in my life I realized I could be physically active, fit,
                workout, and it didn’t have to be in the form of a sport.” Bahar
                now understood why people enjoy being physically active. She had
                finally found the type of physical activity she enjoyed.
            </p>

            <p>
                Bahar got her first personal training certification between the
                age of 16 - 17. Bahar knew this was something that interested
                her and she was so excited to learn more at that point in her
                life. Bahar told us how growing up she often heard people say
                ‘you are never going to have abs because your genetics won’t let
                you.’ As she started to learn more about fitness, she realized
                that was not true. “Every body is different for sure but if you
                are willing to put in the work, you can also change your body. I
                didn’t understand that until I experienced it myself.”
            </p>

            <p>
                We then asked Bahar what it was like to start training so young.
                She shared how her age was one of the biggest things she had to
                overcome as a personal trainer starting off her career. “When
                you are young people assume you don’t know anything. Obviously,
                some things you only learn from experience but in terms of
                knowledge and what you have to offer, age is not always a
                deciding factor here.”
            </p>

            <p>
                Another challenge Bahar has faced has been the lack of financial
                security at the start. “When I was younger I was just figuring
                it out. I was still in school, trying to learn, trying to
                balance it all. That was a challenge.” Bahar talked about how
                she would like people to know that it does eventually start to
                come together and things do start to make sense. Bahar spoke
                about how important it is to focus on your ‘why’ when you are
                trying to build a personal training business. “The reality is
                motivation doesn’t last. Motivation is a feeling. I find this is
                why having a ‘why is important. On the days you don’t feel
                motivated you can focus on your why and remind yourself that
                there is a bigger goal and purpose here.”
            </p>

            <p>
                5 years into being a full-time personal trainer Bahar is a
                source of wisdom and knowledge. Our conversation with her was
                inspiring and thoroughly motivating. We are so grateful for her
                time and excited to watch her grow. You can connect with Bahar
                on Instagram{" "}
                <a href="https://www.instagram.com/yourprobody/">
                    @yourprobody
                </a>{" "}
                or visit her website{" "}
                <a href="https://yourprobody.com/">https://yourprobody.com/</a>.
            </p>
            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/we-know-you-are-doing-this-for-more-than-just-the-money">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/the-art-of-communication">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent13;
