import React, {useRef, useEffect} from 'react'
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import quoteImg from "../../assets/trainerAssests/quotes.png"
import { useLocation } from "react-router-dom";
import video from "../../assets/trainerAssests/tisha/tishaVideo.MP4"


function Tisha() {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/ourtrainers/trainer/Tisha-Henry") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
  return (
      <>
          <header className="trainerHeader tishaHeader" ref={elementRef}>
              <div className="wrapper">
                  <div className="trainerHeaderText">
                      <h1>Meet Tisha</h1>
                      <p>A PLTFRMPRO Trainer</p>
                      <button className="trainerContactButton">
                          <Link to="/workoutform">Contact Tisha</Link>
                      </button>
                  </div>
              </div>
          </header>
          <Helmet>
              <title>Find a Trainer Near Me | TISHA HENRY | PLTFRM</title>

              <meta
                  name="description"
                  content="Tisha is a personal trainer for women in downtown Toronto. She is the best all-in-one functional trainer and kettlebell coach. "
              />
          </Helmet>
          <section className="getToKnow">
              <div className="wrapper">
                  <h2>Get to Know Tisha...</h2>
                  <div className="trainerBio">
                      <img
                          className="trainerQuotes"
                          src={quoteImg}
                          alt="quotes"
                      />
                      <div className="bioText">
                          <p>
                              My journey in fitness began with me being sick and
                              tired of being made fun of because of how skinny I
                              was and I decided to do something about it. I hit
                              the gym and worked on myself….not just my
                              aesthetics but my mind and soul as well.
                          </p>
                          <p>
                              I love working with my clients because I see the
                              old me in them and I love empowering them to build
                              that strength that I know they have inside of
                              them, and eventually seeing their confidence and
                              self-esteem shine through. I was born in Trinidad,
                              lived in St. Vincent, and then spent some years in
                              Mississauga and Toronto.
                          </p>
                      </div>
                  </div>
              </div>
          </section>

          <section className="trainerVideo">
              <div className="wrapper">
                  <video
                      src={video}
                      playsInline
                      controls
                      preload="auto"
                      type="video/mp4"
                      id="blogVideo"
                      title="Tisha Henry"
                  ></video>
              </div>
          </section>

          <section className="qualifications">
              <div className="wrapper">
                  <h2>Qualifications</h2>
                  <p>CanFitPro Certified</p>
                  <p>Fitness and Health Promotion Diploma</p>
              </div>
          </section>
          <section className="quotes">
              <div className="wrapper">
                  <div className="quoteContainer quote1">
                      <h3>#1 Training Advice for People</h3>
                      <p>
                          “Just start and keep it simple. Your movements don’t
                          need to be sexy. Just practice the basics first and
                          get good at them.”
                      </p>
                  </div>
                  <div className="quoteContainer quote2">
                      <h3>What do you love about fitness?</h3>
                      <p>
                          “Seeing my clients change and how excited they get
                          when they also see and feel the change.”
                      </p>
                  </div>
              </div>
          </section>
          <section className="trainerSocials">
              <div className="wrapper">
                  <h2 className="h2FormLink">
                      <Link to="/workoutform">Contact Tisha</Link>
                  </h2>
                  <div className="trainerSocialsContainer">
                      <a
                          href="https://www.instagram.com/getstrongwithcoachtee/"
                          className="iconCircle"
                      >
                          <FontAwesomeIcon
                              icon={faInstagram}
                              className="fa-fw"
                          />
                          <span className="sr-only">Instagram Icon</span>
                      </a>
                      <a
                          href="https://pensight.com/x/sculptyourbodystrong"
                          className="iconCircle"
                      >
                          <FontAwesomeIcon icon={faGlobe} className="fa-fw" />
                          <span className="sr-only">Globe Icon</span>
                      </a>
                  </div>
              </div>
          </section>
      </>
  );
}

export default Tisha