import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost22-img1.png";
import blogImage2 from "../assets/blogAssets/blogPost22-img2.png";

const BlogContent22 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/are-there-too-many-personal-trainers"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>Are There Too Many Personal Trainers?</title>

                <meta
                    name="description"
                    content="If you are thinking about becoming a personal trainer but worried that the training industry is too saturated you need to read this article. We believe that if you have passion for fitness and training you can still build a successful business in today’s competitive environment. We are excited to share why and how you can do that!"
                />
            </Helmet>
            <h2 ref={elementRef}>Are There Too Many Personal Trainers?</h2>
            <h3 className="subTitleH3">
                Is there enough space in the industry for you to stand out and
                succeed?
            </h3>
            <div className="authorContainer">
                <h4>June 12, 2023</h4>
                <p>by PLTFRM</p>
            </div>
            <img
                className="blogImg"
                src={blogImage}
                alt="Multiple graphics of a trainer spotting a client"
            />
            <p>
                The increase in personal trainers we see today compared to 10
                years ago is massive. The fitness industry as a whole has seen
                significant growth and popularity in recent years and only seems
                to be steadily growing.
            </p>
            <p>
                There are many reasons for this jump in popularity. One of the
                main reasons is there is simply a growing demand for this skill.
                People want trainers that they relate to, feel comfortable
                around, and can motivate and guide them to reach their fitness,
                body, and life goals.
            </p>
            <p>
                According to Ibis World the gym, health, and fitness industry in
                Canada was worth US$4.4 billion in 2021. The Fitness Industry
                Council of Canada says there are approximately 30,000 registered
                personal trainers across the country.
            </p>
            <p>What does this mean?</p>
            <p>There are a lot of personal trainers!</p>
            <p>
                Now, these numbers have also got people asking if maybe there
                are <strong>too many</strong> personal trainers?
            </p>
            <p>
                Here is the thing, if you are interested in become a personal
                trainer we would recommend not wasting time focusing on whether
                or not there are too many trainers but focus on what it is{" "}
                <strong>YOU</strong> bring to the table as a trainer.
            </p>
            <p>
                There are so many ways to run a successful personal training
                business today. You can use social media, you can have a
                business that runs online, you can be a trainer at traditional
                gyms, or you can be a trainer at a gym like{" "}
                <a href="https://pltfrm.fit/aboutus">PLTFRM</a>. The point is,
                you have options!
            </p>
            <p>
                If you want to be a trainer, you need to start focusing on YOU
                more.
            </p>
            <p>What can you offer that is unique?</p>
            <p>Why do you want to do it?</p>
            <p>Who are you going to help?</p>
            <p>
                These 3 question can help you get clear on your vision, mission,
                and goals.
            </p>
            <img
                className="blogImgType2 infoGraphic2"
                src={blogImage2}
                alt="An infographic that lists 7 things you need to run a successful business: personal trainer edition"
            />
            <p>
                To run a successful personal training business today the best
                thing can do for yourself is have direction.
            </p>
            <p>
                It’s no secret that there are a lot of personal trainers, the
                numbers prove that there are. The numbers don’t mean that you
                can’t still succeed.
            </p>
            <p>
                If you are passionate and committed to this work there are
                clients on there than need you. When running any kind of
                service-based business you have to figure out who it is that you
                are specifically speaking to you. You need to figure out who
                your service is specifically for. Spend time focus on these
                questions instead of saying the industry is too saturated for
                you to succeed.
            </p>
            <p>
                If you are personal trainer in Toronto and are interested in
                learning more about PLTFRM,{" "}
                <a href="https://calendly.com/pltfrm/30min?month=2023-06">
                    book a virtual call
                </a>{" "}
                with our founder Fred. We can share more about what PLTFRM is
                and about how we can help you grow your business.
            </p>
            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/the-emotionally-intelligent-personal-trainer">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/the-new-age-of-personal-training">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent22;
