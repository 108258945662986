import blogImage from "../assets/blogAssets/blogPost12-img1.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import ShareButtons from "../components/ShareButtons";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const BlogContent12 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/the-art-of-communication"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <>
            <Helmet>
                <title>
                    The Art of Communication: How to Communicate Effectively
                    with Your Clients for Personal Trainers
                </title>

                <meta
                    name="description"
                    content="A big part of running a successful personal training business will come down to effective communication skills. As a personal trainer, you need to be able to inspire and motivate your clients. This blog post will give you 4 ways you can master the art of communication."
                />
            </Helmet>

            <h2 ref={elementRef}>
                The Art of Communication: How to Communicate Effectively with
                Your Clients for Personal Trainers
            </h2>

            <div className="authorContainer">
                <h4>March 20th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage}
                alt="Two personal trainers wearing black t-shirts with the PLTFRM logo."
            />

            <p>
                If you are a personal trainer trying to grow your business,
                effective communication is essential for you to be able to
                establish trust, build relationships, and help your clients
                achieve their fitness goals. A big part of your job is learning
                to communicate, inspire, and motivate your clients. Here are
                some tips to help you do that.
            </p>

            <h3>Be an active listener:</h3>

            <p>
                When you are an active listener with your clients you are
                showing them that you understand their needs and concerns. Your
                clients want to feel heard and acknowledged. Part of your job is
                to listen to their goals, struggles, and questions, and show
                empathy and respect.
            </p>

            <h3>Give positive feedback:</h3>

            <p>
                Following through on fitness goals can be challenging for some
                clients. When you provide positive feedback, you help motivate
                and encourage your clients to stay on track and continue their
                fitness journey. Make sure you are celebrating their successes,
                acknowledge their efforts, and give constructive feedback when
                needed. This will help your clients feel valued and supported.
            </p>

            <h3>Build rapport:</h3>

            <p>
                Building rapport is all about establishing trust and creating a
                positive environment. You want your clients to feel like they
                can come to you with their concerns. You want them to feel safe
                and free to speak their mind.
            </p>

            <h3>Clarify expectations:</h3>

            <p>
                From the very start be clear about what you expect and what your
                clients can expect in return. Clarify the role you will play in
                their journey. Be clear about what you need them to do in order
                for them to see/experience the results they want. This way of
                communicating will set both sides up for success.
            </p>

            <p>
                Effective communication is essential for personal trainers to
                help their clients achieve their fitness goals. By being an
                active listener, giving positive feedback, building a rapport,
                and clarifying expectations you will begging to establish a
                positive relationship with prospective clients. This
                relationship will help clients trust you, lean on you, and keep
                coming back to you.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/you-are-never-too-young-to-follow-your-passions">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/fitness-as-a-lifestyle">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent12;
