import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import quoteImg from "../../assets/trainerAssests/quotes.png";
import { useLocation } from "react-router-dom";

function KD() {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/ourtrainers/trainer/KD") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
    return (
        <>
            <header className="trainerHeader kdHeader" ref={elementRef}>
                <div className="wrapper">
                    <div className="trainerHeaderText">
                        <h1>Meet KD</h1>
                        <p>A PLTFRMPRO Trainer</p>
                        <button className="trainerContactButton">
                            <Link to="/workoutform">Contact KD</Link>
                        </button>
                    </div>
                </div>
            </header>
            <Helmet>
                <title>
                    Certified Personal Trainer | Kettlebell Training | KD |
                    PLTFRM
                </title>

                <meta
                    name="description"
                    content="Train with KD at PLTFRM in the downtown Toronto core. Affordable prices, state-of-the-art equipment, and the best fitness coaches."
                />
            </Helmet>

            <section className="getToKnow">
                <div className="wrapper">
                    <h2>Get to Know KD...</h2>
                    <div className="trainerBio">
                        <img
                            className="trainerQuotes"
                            src={quoteImg}
                            alt="quotes"
                        />
                        <div className="bioText">
                            <p>
                                I began my fitness journey through sports. I was
                                always involved with many sports, primarily
                                basketball and I found myself in need of a way
                                to enhance my game. My on-court skillset wasn't
                                the problem by my strength was. This is what led
                                me into a weight room and started my fitness
                                journey.
                            </p>
                            <p>
                                After my amateur career in basketball was over,
                                the gym was always there no matter what and I
                                knew I wanted to make a career out of it. I
                                studied fitness and health at Humber College and
                                since then I have been improving my knowledge of
                                training and health to better fit my personal
                                goals and anyone who trusts me to help them.
                            </p>
                            <p>
                                I enjoy working with my clients because I see
                                training as more of a health-related field. I
                                like to think of personal training as medicine
                                and as something that can help prolong life and
                                keep clients pain-free and this gives me a lot
                                of satisfaction.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="qualifications">
                <div className="wrapper">
                    <h2>Qualifications</h2>
                    <p>Canfit Pro</p>
                    <p>Agatsu Kettlebell Level 1</p>
                </div>
            </section>
            <section className="quotes">
                <div className="wrapper">
                    <div className="quoteContainer quote1">
                        <h3>#1 Training Advice for People</h3>
                        <p>“Find a consistent time to train”</p>
                    </div>
                    <div className="quoteContainer quote2">
                        <h3>What do you love about fitness?</h3>
                        <p>
                            “The one thing about fitness I love the most is how
                            it has the potential to turn your life around for
                            the better.”
                        </p>
                    </div>
                </div>
            </section>
            <section className="trainerSocials">
                <div className="wrapper">
                    <h2 className="h2FormLink">
                        <Link to="/workoutform">Contact KD</Link>
                    </h2>
                    <div className="trainerSocialsContainer">
                        <a
                            href="https://www.instagram.com/ballsnbells/"
                            className="iconCircle"
                        >
                            <FontAwesomeIcon
                                icon={faInstagram}
                                className="fa-fw instagramIcon"
                            />
                            <span className="sr-only">Instagram Icon</span>
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default KD;
