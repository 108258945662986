import blogImage from "../assets/blogAssets/blogPost14-img1.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import ShareButtons from "../components/ShareButtons";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const BlogContent14 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/we-know-you-are-doing-this-for-more-than-just-the-money"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>
                    We know you are doing this for more than Just the Money
                </title>

                <meta
                    name="description"
                    content=" If you are a personal trainer we know you are passionate about the work you do. You can’t be a good personal trainer unless you really love helping people. If this is you, congrats. You are doing work you love and that is a victory in itself. Let us help you focus on how you can support yourself better and smarter now!"
                />
            </Helmet>
            <h2 ref={elementRef}>
                We know you are doing this for more than Just the Money
            </h2>
            <div className="authorContainer">
                <h4>April 6th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <h3>4 things to keep in mind when setting your 1:1 rates</h3>

            <p>
                As a personal trainer, you probably love fitness. You love how
                your body and mind feel after a workout like you could do almost
                anything.
            </p>
            <p>
                Do you remember the day you decided you wanted to help other
                people feel this way?
            </p>
            <p>Do you remember the day someone first paid you to train them?</p>
            <p>
                It was probably exhilarating. You probably had one of those
                moments where you were like I can’t believe someone is paying me
                to do something I love.
            </p>
            <p>
                Now, do you remember the day you decided you wanted this to be
                your full-time job?
            </p>
            <p>
                As a personal trainer, you do so much more than just help people
                get fit. You know the value and joy working out can bring to the
                rest of your life.
            </p>
            <p>
                A big goal of yours is to probably help change their lives
                fitness changed yours.
            </p>
            <p>This is your passion.</p>
            <p>
                Now, in order to live your passion and make a career out of it,
                there are practical needs of yours that must be met.
            </p>
            <p>
                As much as you must love being a personal trainer, you also need
                it to support you.
            </p>
            <p>
                The traditional way of being a full-time trainer is to work at a
                gym.
            </p>
            <p>
                According to some data pulled from alis Alberta, the average
                salary for a personal trainer in Alberta is $19,502 a year.
            </p>
            <img
                className="blogImg"
                src={blogImage}
                alt="Data pulled from alis alberta on avg. Personal trainer salary"
            />
            <p>
                A personal trainer, in a bigger city, will probably make more
                than this. The bottom line is the pay is not great.
            </p>
            <p>
                Most trainers need a second income or need to work a lot of
                hours in order to make ends meet.
            </p>
            <p>
                This is where a business like PLTFRM comes in. We want personal
                trainers to get paid for the value they bring.
            </p>
            <p>We want you to be your own boss.</p>
            <p>
                All we do is provide you with a gym to train your clients in. A
                gym you can work out of. A gym you can run your business on your
                own terms. You set your hours and you set your rates.
            </p>
            <p>
                When you set your rate, you get to set it based on the value you
                bring. Here are 4 things you need to consider…
            </p>

            <h3>1. Geographic Location</h3>
            <p>
                If you are training in a high-demand big city, set your rates
                accordingly.
            </p>

            <h3>2. Overhead Costs</h3>
            <p>
                So many trainers forget to think about all the overhead costs
                that go into a training session.
            </p>
            <p>
                All other businesses include this cost in their retail prices.
                You should as well!
            </p>

            <h3>3. Level of experience and expertise</h3>
            <p>
                If you have been training for some time now, don’t be afraid to
                raise your prices.
            </p>
            <p>The right clients will know the value you bring.</p>

            <h3>4. Industry Standard</h3>
            <p>Don’t be afraid to have conversations with your peers.</p>
            <p>
                Don’t base your rates on what someone else is charging but use
                that knowledge to inform your own decisions.
            </p>
            <p>
                We are getting ready to open our door real soon! If you would
                like to be one of the founding trainers at our launch, sign up
                down below and our team will be in touch with details.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/how-to-make-the-most-out-of-your-working-hours">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/you-are-never-too-young-to-follow-your-passions">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent14;
