import React,{useEffect, useRef} from 'react'
import { Helmet } from 'react-helmet';
import NavBar from "../components/NavBar";
import { Link } from "react-router-dom";
import {Link as Scroll} from "react-scroll"
import image1 from "../assets/pltfrmImage11.png";
import image2 from "../assets/pltfrmImage12.png";
import tisha from "../assets/trainerAssests/tisha/tishaHeadshot.png"
import kareem from "../assets/trainerAssests/kareem/kareemHeadshot.png"
import daniela from "../assets/trainerAssests/daniela/danielaHeadshot.png";
import kd from "../assets/trainerAssests/kd/kdHeadshot.jpg";

import { useLocation } from "react-router-dom";
import "../styling/OurTrainers.scss";

function OurTrainers() {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/ourtrainers") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },[location]
    );

  return (
      <div className="ourTrainersPage">
          <Helmet>
              <title>PERSONAL TRAINERS IN TORONTO | PLTFRM</title>

              <meta
                  name="description"
                  content="Private gym with the best personal trainers in downtown Toronto.  Offering affordable personal training. We are home to the best trainers in Toronto."
              />
          </Helmet>
          <header className="ourTrainersHeader" ref={elementRef}>
              <div className="wrapper">
                  <NavBar />
              </div>
              <div className="ourTrainersHeaderContent">
                  <div className="wrapper">
                      <div className="ourTrainersHeaderText">
                          <h1>PLTFRMPROS</h1>
                          <p>
                              Matching you with the best personal trainer for
                              you
                          </p>
                          <div className="buttonContainer">
                              <button>
                                  <Scroll to="trainer">
                                      Find your trainer
                                  </Scroll>
                              </button>
                              <button>
                                  <Scroll to="trainer">
                                      PLTFRMPRO Trainers
                                  </Scroll>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </header>

          <section className="whyWorkWithATrainer">
              <div className="wrapper">
                  <h2>
                      Why work with a{" "}
                      <span className="titleImportant">PLTFRMPRO</span> Trainer?
                  </h2>
                  <p>
                      At <span className="importantText">PLTFRM</span>, we only
                      partner with{" "}
                      <span className="importantText">
                          certified personal trainers
                      </span>{" "}
                      who are experienced in helping people reach their health
                      and fitness goals.
                  </p>
                  <p>
                      No matter where you are in your fitness journey, the
                      fitness professionals hosted at{" "}
                      <span className="importantText">PLTFRM</span> can help you
                      reach your goals. Whether you're aiming to build strength,
                      lose weight, or enhance cardio,{" "}
                      <span className="importantText">PLTFRM</span> has curated
                      a community of dedicated trainers who can be your partners
                      in achieving overall fitness.
                  </p>
                  <p>
                      Everyone's fitness journey is different which is why
                      having personalized support can help elevate your journey.
                      Our trainers are experienced in tailoring workouts for
                      various needs. Whether you're just starting out, seeking
                      guidance on overall fitness, or a seasoned enthusiast, our
                      trainers are here to elevate you.
                  </p>
              </div>
          </section>

          <section className="trainerIconSection">
              <div className="wrapper">
                  <div className="findYourTrainer">
                      <img
                          src={image1}
                          alt="woman lifting a dumbbell at 90 degrees. A list with titled, 5 tips to get stronger. Create a plan, Get an accountability buddy, choose a tie of day to train, try one new activity a week, stay consistent"
                          className="trainerIcon"
                      />
                      <h3>
                          Find someone you resonate with. Your journey is
                          unique, and so is your trainer.
                      </h3>
                  </div>

                  <div className="findYourTrainer secondIcon">
                      <h3>
                          At PLTFRM, we help you through the process by first
                          vetting any personal trainers before they become
                          PLTFRMPROS.
                      </h3>

                      <img
                          src={image2}
                          alt="Fitness Trainer Content Calender.  Different content prompts for everyday of the week."
                          className="trainerIcon"
                      />
                  </div>
              </div>
          </section>

          <section className="howDoYouChooseTheRightTrainer">
              <div className="wrapper">
                  <h2>How do you choose the right trainer?</h2>
                  <div>
                      <p>
                          We recommend going through the roster of trainers
                          based at PLTFRM and learning about their stories and
                          specializations. Once you find someone that resonates
                          with you, reach out to them for a consultation.
                      </p>
                      <p>
                          Alternatively, the PLTFRM team is happy to assist in
                          pairing you up with a trainer. Get in touch and let us
                          know what you are looking for. A member of our team
                          will reach out to pair you up with the perfect trainer
                          for your specific goals.
                      </p>
                  </div>
                  <div className="buttonContainer">
                      <button>
                          <Scroll to="trainer">Our Trainers</Scroll>
                      </button>

                      <button>
                          <Link to="/workoutform">Get In touch</Link>
                      </button>
                  </div>
              </div>
          </section>
          <section className="trainerProfileSection" id="trainer">
              <div className="wrapper">
                  <h2>
                      <span className="titleImportant">PLTFRMPRO</span> Trainers
                  </h2>
                  <div className="trainerContainer">
                      <img
                          className="trainerProfileImage"
                          src={kareem}
                          alt="Kareem Whyte"
                      />
                      <div className="trainerText">
                          <h3>Kareem Whyte</h3>
                          <div>
                              <p>
                                  <strong>Specializations:</strong> Strength and
                                  Conditioning, Bodybuilding, Nutrition,
                                  Supplementation
                              </p>
                              <p>
                                  Kareem has been a trainer for 6 years and
                                  works predominantly with former athletes and
                                  formerly active men wanting to build strength
                                  and muscle.
                              </p>
                              <p>
                                  Kareem's fitness journey started with his own
                                  fitness transformation. He is fully aware of
                                  the work and dedication it takes to build
                                  strength. Today, a big part of his motivation
                                  is to help people build the strength and
                                  muscle they wish they could have.
                              </p>
                          </div>
                          <button className="buttonContainer">
                              <Link to="/ourtrainers/trainer/Kareem-Whyte">
                                  More about Kareem
                              </Link>
                          </button>
                      </div>
                  </div>

                  <div className="trainerContainer">
                      <img
                          className="trainerProfileImage"
                          src={tisha}
                          alt="Tisha Henry"
                      />
                      <div className="trainerText">
                          <h3>Tisha Henry</h3>
                          <div>
                              <p>
                                  <strong>Specializations:</strong> Strength and
                                  Kettlebell Coach
                              </p>

                              <p>
                                  Tisha has been a personal trainer for over 8
                                  years. She works with busy women past the age
                                  of 35 years who want to build strength and
                                  sculpt their bodies. Tisha is creative at
                                  heart and also makes soaps.
                              </p>
                              <p>
                                  She loves empowering her clients to not just
                                  build strength but also confidence.
                              </p>
                          </div>
                          <button className="buttonContainer">
                              <Link to="/ourtrainers/trainer/Tisha-Henry">
                                  More about Tisha
                              </Link>
                          </button>
                      </div>
                  </div>
                  <div className="trainerContainer">
                      <img
                          className="trainerProfileImage"
                          src={daniela}
                          alt="Daniela"
                      />
                      <div className="trainerText">
                          <h3>Daniela</h3>
                          <div>
                              <p>
                                  <strong>Specializations:</strong> Body
                                  Liberation
                              </p>
                              <p>
                                  Daniela has been a trainer for 5 years. She
                                  works with people who are ready to take up
                                  space in the fitness world. Daniela is all
                                  about exploring the body through movement. She
                                  strongly believes that being healthy looks and
                                  feels different for everyone. If you are ready
                                  to find joy in staying healthy and building
                                  strength, Daniela is the trainer for you.
                              </p>
                          </div>
                          <button className="buttonContainer">
                              <Link to="/ourtrainers/trainer/Daniela">
                                  More about Daniela
                              </Link>
                          </button>
                      </div>
                  </div>
                  <div className="trainerContainer">
                      <img className="trainerProfileImage" src={kd} alt="KD" />
                      <div className="trainerText">
                          <h3>KD</h3>
                          <div>
                              <p>
                                  <strong>Specializations:</strong> Kettlebell
                                  Training
                              </p>
                              <p>
                                  KD believes that a consistent fitness routine
                                  can truly change someones life for the better.
                                  According to him, a fitness routine can teach
                                  you how to schedule your day, how to be
                                  patient with yourself, how to be kind to
                                  yourself, and how to push yourself to places
                                  once thought imposible. If you are ready stop
                                  making excuses and get lazer focused on your
                                  fitness and life goal KD is the trainer for
                                  you!
                              </p>
                          </div>
                          <button className="buttonContainer">
                              <Link to="/ourtrainers/trainer/KD">
                                  More about KD
                              </Link>
                          </button>
                      </div>
                  </div>
              </div>
          </section>
          <section className="needHelpPickingATrainer">
              <div className="wrapper">
                  <h2>Need Help Picking a Trainer?</h2>
                  <div>
                      <p>
                          A personal trainer can play a very significant part in
                          your fitness journey which is why it’s important to
                          take your time to make sure you have the right fit!
                      </p>
                      <p>
                          Get in touch with our team at PLTFRM and we can help
                          pair you up with the right trainer for your needs and
                          goals.
                      </p>
                      <div className="buttonContainer">
                          <button>
                              <Link to="/workoutform">Get In touch</Link>
                          </button>
                      </div>
                  </div>
              </div>
          </section>
      </div>
  );
}

export default OurTrainers