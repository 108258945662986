import React from 'react'
import "../styling/Specials.scss"

function Specials() {
  return (
      <section className="specialsSection">
          <div className="wrapper">
              <h3 className="titleImportant">Trial Pass</h3>
              <h2>
                  1 week unlimited for{" "}
                  <span className="titleImportant">$99</span>
              </h2>
              <p>
                  Unlimited Use of Toronto’s Newest Private Training Gym &
                  Fitness Coworking Space
              </p>
              <button>
                  <a href="https://www.wellnessliving.com/catalog/pltfrm">
                      Book a Time
                  </a>
              </button>
          </div>
      </section>
  );
}

export default Specials