import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost20-img1.png";
import blogImage2 from "../assets/blogAssets/blogPost20-img2.png";

const BlogContent20 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/exceptional-customer-service"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>
                    Exceptional Customer Service: Personal Trainer Edition
                </title>

                <meta
                    name="description"
                    content="If you are a personal trainer that is struggling with how to communicate with clients this blog post is for you. We break down the complexities of sales and marketing into understandable terms you need to focus on in order to provide better customer service. Whether it’s online or in-person communication you need to work on this post will start you off in the right direction. "
                />
            </Helmet>

            <h2 ref={elementRef}>Exceptional Customer Service</h2>
            <h3 className="subTitleH3">Personal Trainer Edition</h3>

            <div className="authorContainer">
                <h4>May 23, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage}
                alt="3 women trainers smiling and wearing t-shits with the PLTFRM logo"
            />

            <p>
                As a personal trainer your{" "}
                <strong>customer service skills</strong> need to be exceptional
                because you are in the business of people. Customer service
                might sound like a “sales” or “marketing” word but at the end of
                the day it’s simply all about how you interact with people, how
                you make them feel, and how they remember you. If you want to
                build a <strong>successful, scalable,</strong> and{" "}
                <strong>sustainable</strong> personal training business you need
                to be able to do those three things well.
            </p>

            <p>Let's break it down.</p>

            <h3>How You Interact With People</h3>

            <p>
                This is all about speak and share your expertise with your
                clients and/or audience. As a personal trainer you are more
                educated about fitness. While this is great, the more important
                part is how you communicate this with others. This is what
                matters. If you can’t communicate your expertise you do not have
                a job. Knowing how to interact with people is vital for a
                personal trainer.
            </p>

            <h3>How You Make People Feel</h3>

            <p>
                You need to be able to inspire and motivate people to show up
                for the work. The work might not always be easy or enjoyable. As
                a trainer you are their <strong>accountability</strong> partner.
                There is an art to inspiring and motivating people. It needs to
                be done in a manner that is empowering. Learning how to do this
                is important in growing and sustaining a client base.
            </p>

            <h3>How Do People Remember You</h3>

            <p>
                Whether is social media or an in person interaction you need to
                stand out and this ties into the point above. Do you remember
                the saying ‘people will forget what you did but they will never
                forget how you made them feel’?
            </p>

            <p>Well, it's true.</p>

            <p>
                When someone remembers how you made them feel they are more
                likely to come back to you or even recommend you to someone
                else. When you are in the business of people things like this
                are so important. You business depends on people thinking of you
                whenever they are in need of your service.
            </p>

            <img
                className="blogImgType2 infoGraphic2"
                src={blogImage2}
                alt="Infographic listing tips on how to deliver exceptional customer service for personal trainers by PLTFRM"
            />

            <p>
                Being a personal trainer can be an exciting and enriching job.
                It’s also a job that requires you have a knack for people.
            </p>

            <p>
                This does not mean you have to be outgoing or you have to be the
                loudest person in the room.
            </p>

            <p>
                This just means you need to know your strengths when is comes to
                communicating with people and make sure to play to them.
            </p>

            <p>
                People will be drown to your unique way of communicating. The
                more you are able to be yourself the more the right clients will
                find you and gel well with you.
            </p>

            <p>
                Remember, you aren’t trying to be the answer to everyone's
                problem. You are trying to find the people who need what you
                specifically provide.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/the-new-age-of-personal-training">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/Emily-Dugan-on-women-and-fitness">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent20;
