import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import blogImg from "../assets/blogAssets/blogPost5-img1.png"
import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

const BlogContent5 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/4-tips-to-start-a-fitness-business"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );
    return (
        <>
            <Helmet>
                <title>
                    4 Steps to Start a Fitness Training Business in Toronto
                </title>

                <meta
                    name="description"
                    content="Starting a business can seem overwhelming and challenging. We are here to make it simple for you. This blog post discusses insurance coverage, registering your business and some of the exciting parts of being a business owner. Stop making excuses and start building your business today!"
                />
            </Helmet>

            <h2 ref={elementRef}>
                4 Steps to Start a Fitness Training Business in Toronto
            </h2>

            <div className="authorContainer">
                <h4>January 16th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImg}
                alt="The PLTFRM reception and gym space"
            />

            <p>
                You’re not the first fitness professional to start their own
                training business in Toronto. Learn from others’ experiences!
                Here are key points to navigate as you take control of your
                career.
            </p>

            <h3>
                Step 1: Figure out if you need to register your business in the{" "}
                <span className="blogPostHeaderSpan">
                    <a href="https://www.ontario.ca/page/ontario-business-registry">
                        Ontario Business Registry
                    </a>
                </span>
            </h3>

            <p>
                When you’re just starting out as an independent fitness
                professional for one-on-one training, odds are you want the
                simplest and least expensive setup to start making money. That
                means you are probably going to want a sole proprietorship.
                There is no separation between the sole proprietorship as a
                business and the person who owns that business — the business is
                its owner.
            </p>

            <p>
                Thankfully, if you plan to do business under your own personal
                name, you don’t have to register at all. That means, on any
                written contracts or invoices, you’d identify your business by
                your personal name. However, if you want to go by GAINZTRAIN,
                you should register your business name with the Ontario Business
                Registry. There’s a $60 fee to do that.
            </p>

            <h3>Step 2: Make sure you buy insurance for fitness trainers</h3>

            <p>
                Your clients want to know they’re covered for accidental
                injuries during training. That’s why most independent fitness
                trainers should have commercial general liability insurance and
                professional liability insurance to a combined limit of two
                million dollars. Despite that big number, you’re typically only
                paying about $300 or $400 per year if you have a recognized
                certification. It’s a small price to be protected if your client
                gets injured.
            </p>

            <h3>Step 3: Get clear on who your target market is.</h3>

            <p>
                One of the great benefits to being your own boss is deciding
                what kind of clients you want to serve. Is your ideal client a
                corporate desk jockey wanting an adrenaline fix? How about the
                high school or undergraduate athlete? Do you specialize in
                hypertrophy programming for bodybuilders? Are you planning to be
                a weight loss and diet behaviour coach? The clientele you want
                to maintain and attract: they define your business plan.
            </p>

            <p>
                Make sure you’re up to date on fitness trends for those
                audiences. Join the conversation on social media. Figure what
                the concerts of your target audience is. Your content should
                speak to them. Remember that the services you offer are not for
                everyone but for the clients you choose. Choose to have a voice
                worth listening to — not for everyone, but for your audience.
            </p>

            <h3>
                Step 4: Get your as much support with growing your business as
                you can!
            </h3>

            <p>
                Fitness professionals should be the first to appreciate the
                value of a coach. Yes, we can all execute all the steps
                necessary to start a business, but we’ll do better if we have an
                objective eye on our form, our programming, and our goals. You
                should seek out settings with that network of knowledge and
                experience.
            </p>

            <p>
                As your business becomes more profitable, some choices require
                more careful consideration. While we’ve covered the basics to
                get you started here, one day you’ll ask:
            </p>

            <ul className="blogpostList">
                <li>
                    <p>When do I have to start charging HST?</p>
                </li>
                <li>
                    <p>
                        At what point does it make sense to take on employees?
                        Or should I stay solo?
                    </p>
                </li>
                <li>
                    <p>How should I set my billing rate?</p>
                </li>
                <li>
                    <p>Does it make sense to incorporate?</p>
                </li>
            </ul>

            <p>
                At PLTFRM, we’re building that network. We want the fitness
                businesses we host to grow, thrive, and lean on PLTFRM’s
                community for insight. PLTFRM will always make an effort to
                connect members to the guidance they need!
            </p>

            <p>
                Reach out to us at{" "}
                <a className="footerEmail" href="mailto:marketing@pltfrm.fit">
                    marketing@pltfrm.fit
                </a>
            </p>

            <p>
                Follow us on{" "}
                <a href="https://www.instagram.com/pltfrm.fit/?hl=en">
                    Instagram{" "}
                </a>
                and{" "}
                <a href="https://www.linkedin.com/company/pltfrmfit/?">
                    Linkedin
                </a>{" "}
                for daily business tips!
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/4-best-tools-for-online-businesses">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/building-confidence-and-resilience-in-a-competitive-world">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BlogContent5;
