
import { useEffect, useState } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";

const ActiveCampaignContentGuide = (props) => {
    const [loadForm, setLoadForm]=useState(false)
    useEffect(() => {
        if (props.download === true) {
            setLoadForm(true);
        } else {
            setLoadForm(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.download]);

    return (
        <>
            <div className="contentGuideContainer">
                <div>
                    {loadForm=== true ? (
                        <>
                            <HelmetProvider>
                                <Helmet>
                                    <script
                                        src="https://pltfrm.activehosted.com/f/embed.php?id=3"
                                        type="text/javascript"
                                        charset="utf-8"
                                    ></script>
                                </Helmet>
                            </HelmetProvider>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ActiveCampaignContentGuide;

