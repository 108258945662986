import blogImage1 from "../assets/blogAssets/blogImg1.png";
import blogImage2 from "../assets/blogAssets/blogPost17-img1.png";


import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ShareButtons from "../components/ShareButtons";

const BlogContent17 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/the-business-side-of-client-acquisition"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>
                    The Business Side of Client Acquisition and the human side
                    of it
                </title>

                <meta
                    name="description"
                    content="You should never feel bad about actively looking for new clients. Your business needs clients in order to be successful. In this blog post, we get right into it and talk about how you can acquire clients at any stage in your business."
                />
            </Helmet>
            <h2 ref={elementRef}>The Business Side of Client Acquisition </h2>
            <h3 className="subTitleH3">and the human side of it</h3>
            <div className="authorContainer">
                <h4>April 20th, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage1}
                alt="Two personal trainers in PLTFRM t-shirts. One trainer is adjusting the other in a seated yoga pose."
            />

            <p>
                If you are a personal trainer we will take a wild (not so wild)
                guess that you love fitness. You are in this business of
                training others because, at some point in your life, you have
                experienced the benefits of working out yourself. Now, you want
                to share that feeling with others. You want other people to feel
                as strong and powerful in their bodies and min as you feel in
                yours. When you think about it this way, it’s a pretty noble
                profession.
            </p>

            <p>
                Now, let’s talk about the facts. To run a{" "}
                <strong>successful personal training business</strong> you need
                clients who you pay. This is a business at the end of the day
                and you need to be able to support yourself, your lifestyle, and
                anyone that depends on you. You need clients.
            </p>

            <h3>How do you get these clients?</h3>

            <p>
                Well, there are multiple ways to acquire clients. The way you
                acquire clients is going to depend on which{" "}
                <strong>stage your business is in.</strong>
            </p>

            <p>
                If you are trying to grow your business you are going to need
                more than one stream through which you acquire clients. Let’s
                break down what these streams could look like.
            </p>

            <ol className="blogList numberedList">
                <li>
                    <h4>Gyms and Fitness Studio</h4>
                    <p>
                        Gyms and studios can be great places to find clients
                        especially when you are just starting out. Clients will
                        often come to you here and you can start building a
                        reputation and name for yourself.
                    </p>
                </li>
                <li>
                    <h4>Online Platforms</h4>
                    <p>
                        Social media, fitness apps, personal training websites…
                        there are so many different platforms you can share
                        valuable content with your audience. Through online
                        platforms, you can reach clients in any part of the
                        world.
                    </p>
                </li>
                <li>
                    <h4>Referrals and Current Client</h4>
                    <p>
                        Your current clients are your greatest testimonial and
                        sales pitch. Work on the relationships you have with
                        your current clients and let them go out and spread the
                        word about your services.
                    </p>
                </li>
                <li>
                    <h4>Corporate Wellness Programs</h4>
                    <p>
                        Many companies will offer corporate wellness classes. If
                        you are able to get in on some of these classes you can
                        start to build relationships with people beyond your own
                        circles.
                    </p>
                </li>
            </ol>

            <p>
                These are all just some of the ways you can work on acquiring
                clients.
            </p>

            <p>
                Once you’ve met a person let’s discuss how you turn them into a
                client. This is where the ‘human’ side comes in.
            </p>

            <p>
                People want to trust and know you. They will buy from you or
                invest in you only if they trust you can get them the results
                they want.
            </p>

            <img
                className="blogImgType2 infoGraphic2"
                src={blogImage2}
                alt="Infographic with 7 ways personal trainers can build trust with their clients."
            />

            <p>
                To build trust with clients you need to be able to{" "}
                <strong>communicate</strong> clearly with them. Set expectations
                and goals from the start.
            </p>

            <p>
                Another way you <strong>build trust</strong> is to{" "}
                <strong>personalize</strong> your programs and pitch to each
                client. Show them that you are willing to meet them where they
                are to get them to where they want to be.
            </p>

            <p>
                Lastly, remember you need to keep <strong>educating</strong>{" "}
                yourself. The more you focus on learning and getting better at
                your job the more your clients will trust you. If they are able
                to see and feel your dedication to being the best you can be,
                they will be more ready to invest in you.
            </p>

            <p>
                When you first start your business it’s normal to be nervous
                about interacting with clients. Remember, your nerves will
                subside with time and experience. You will also get better at it
                the more you do it.
            </p>

            <p>
                Growing a business takes a lot of work and effort. It is a
                process that never really ends. If you are passionate about it,
                it can be a very rewarding journey as well.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/a-conversation-with-KD-about-life-and-basketball">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/the-two-communities-every-personal-trainer-must-build">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent17;
