import "../styling/Footer.scss"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons'

const Footer = () =>{

    return (
        <footer>
            <div className="wrapper">
                <div className="logoContainer footerLogo">
                    <div className="logo3"></div>
                </div>
                <div className="footerContent">
                    <div className="footerInternalLinks">
                        <h4>Company</h4>
                        <ul className="footerLinks">
                            <li>
                                <Link to="/forfitnessprofessionals">
                                    <p>For Fitness Professionals</p>
                                </Link>
                            </li>
                            <li>
                                <Link to="/aboutus">
                                    <p>About Us</p>
                                </Link>
                            </li>
                            <li>
                                <Link to="/form">
                                    <p>Become a PLTFRM Pro</p>
                                </Link>
                            </li>

                            <li>
                                <Link to="/memberships">
                                    <p>Memberships</p>
                                </Link>
                            </li>
                            <li>
                                <Link to="/blog">
                                    <p>Blog</p>
                                </Link>
                            </li>
                            <li>
                                <Link to="/ourtrainers">
                                    <p>Member Trainers</p>
                                </Link>
                            </li>
                            <li>
                                <Link to="/form">
                                    <p>Contact Us</p>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="emailLinks">
                        <h4>Contact</h4>

                        <a
                            className="footerEmail"
                            href="mailto:marketing@pltfrm.fit"
                        >
                            marketing@pltfrm.fit
                        </a>
                        <a href="https://www.termsfeed.com/live/b89973ce-ce6c-4d68-8b81-2eff64cacd2c">
                            Privacy Policy
                        </a>
                    </div>

                    <div className="contactLinks">
                        <h4>Social</h4>
                        <ul className="socials">
                            <li>
                                <a
                                    href="https://twitter.com/PLTFRMfit"
                                    className="twitterIcon"
                                >
                                    <FontAwesomeIcon
                                        icon={faTwitter}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">
                                        Twitter Icon
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/profile.php?id=100087595012867"
                                    className="iconCircle"
                                >
                                    <FontAwesomeIcon
                                        icon={faFacebook}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">
                                        Facebook Icon
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/pltfrm.fit/?hl=en"
                                    className="iconCircle"
                                >
                                    <FontAwesomeIcon
                                        icon={faInstagram}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">
                                        Instagram Icon
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/pltfrmfit"
                                    className="iconCircle"
                                >
                                    <FontAwesomeIcon
                                        icon={faLinkedin}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">
                                        LinkedIn Icon
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="http://www.tiktok.com/@pltfrm.fit"
                                    className="tiktokIcon"
                                >
                                    <FontAwesomeIcon
                                        icon={faTiktok}
                                        className="fa-fw"
                                    />
                                    <span className="sr-only">Tiktok Icon</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer