import NavBar from "../components/NavBar"
import "../styling/ContentGuide.scss"

import pltfrmTips from "../assets/pltfrmTips.jpeg";
import calendar from "../assets/pltfrmCalendar.png";
import blog1Thumb from "../assets/blogAssets/blogThumbnails/blogpost1thumbImage.png";
import blog3Thumb from "../assets/blogAssets/blogThumbnails/blogPost3-thumbnail.png";

import { useState, useEffect, useRef} from "react";
import { Link, useLocation } from "react-router-dom";

import ActiveCampaignContentGuide from "../components/ActiveCampaignContentGuide";

const ContentGuide =()=>{
    const [download, setDownload] =useState(false)

    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(function () {
            if (location.pathname === "/forfitnessprofessionals/contentguide") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
            setDownload(false)
        },[location]
        );
        
        const handleClick=()=>{
            setDownload(!download)
        }

    
    return (
        <div className="contentGuidePage" ref={elementRef}>
            <header className="contentGuideHeader">
                <div className="wrapper">
                    <NavBar />
                </div>
                <div className="contentGuideHeaderContent">
                    <div className="wrapper">
                        <div className="contentGuideHeader">
                            <h1>A Content Creation Guide</h1>
                            <h3>For Fitness Trainers</h3>

                            <div className="buttonContainer">
                                <button onClick={handleClick}>
                                    download now
                                </button>

                                <button>
                                    <Link to="/form">Become a PLTFRMPRO</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section className="contentGuideBannerText">
                <div className="wrapper">
                    {download ? (
                        <ActiveCampaignContentGuide download={download} />
                    ) : (
                        <></>
                    )}
                    <h2>
                        a guide to help you{" "}
                        <span className="importantText">create content</span>{" "}
                        that <span className="importantText">converts</span>
                    </h2>
                </div>
            </section>

            <section className="whatsIncluded">
                <div className="wrapper">
                    <h3>What's included:</h3>
                    <ul>
                        <li>
                            Journaling prompts to help you create a content
                            strategy.
                        </li>
                        <li>
                            An array of content ideas to get the creativity
                            flowing.
                        </li>
                        <li>
                            A collection of software/apps to create content.
                        </li>
                        <li>Instagram templates to get you started.</li>
                        <li>
                            A 1 month content calendar to keep you organized and
                            motivated.
                        </li>
                    </ul>
                </div>
            </section>

            <section className="contentTipsCalendar">
                <div className="wrapper">
                    <div className="contentGuideContent contentGuideContent1">
                        <img
                            src={pltfrmTips}
                            alt="woman lifting a dumbbell at 90 degrees. A list with titled, 5 tips to get stronger. Create a plan, Get an accountability buddy, choose a tie of day to train, try one new activity a week, stay consistent"
                            className="contentTipsImg"
                        />
                        <div>
                            <h3>
                                Empowering{" "}
                                <span className="importantText">you</span> to be
                                <span className="importantText"> your</span> own
                                boss
                            </h3>
                        </div>
                    </div>

                    <div className="contentGuideContent">
                        <div>
                            <h3>
                                Helping{" "}
                                <span className="importantText">you</span> build
                                <span className="importantText"> your</span> own
                                business
                            </h3>
                        </div>
                        <img
                            src={calendar}
                            alt="Fitness Trainer Content Calender.  Different content prompts for everyday of the week."
                            className="contentCalendarImg"
                        />
                    </div>
                    <button onClick={handleClick}>Download Now</button>
                </div>
            </section>

            <section className="signUpToday contentSignUp">
                <div className="wrapper">
                    <h2 className="fitnessSectionTitle">
                        Become a
                        <span className="importantHeader"> PLTFRMPRO</span>{" "}
                        today
                    </h2>
                    <p>
                        When you sign up you will still be an independent. You
                        will simply get access to our rates and be able to book
                        spaces and time slots to use PLTFRM's facilities.
                    </p>

                    <button>
                        <Link to="/form">Sign up</Link>
                    </button>

                    <p className="disclaimerText">
                        When you sign up today you will get first priority at
                        our *SPECIAL* introductory rates.
                    </p>
                </div>
            </section>

            <section className="contentBlogSection">
                <div className="wrapper">
                    <h3>
                        Follow our blog for
                        <span className="importantText">
                            {" "}
                            Fitness Trainers
                        </span>
                    </h3>
                    <div className="blogFlexContainer">
                        <div className="blogContainer">
                            <img
                                src={blog1Thumb}
                                alt="4 ways to grow your fitness business on social media"
                            />
                            <p>
                                4 Ways to Grow Fitness Business on Social Media
                            </p>
                            <button>
                                <Link to="/blog/blogPost/grow-your-business">
                                    read more
                                </Link>
                            </button>
                        </div>

                        <div className="blogContainer">
                            <img src={blog3Thumb} alt="Canfitpro" />
                            <p className="canfitproText">
                                Key Takeaways from the 2022 canfitpro Health and
                                Fitness Trends Survey
                            </p>
                            <button>
                                <Link to="/blog/blogPost/key-takeaways-from-2022-canfitpro-survey">
                                    read more
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ContentGuide