import { Helmet, HelmetProvider } from "react-helmet-async";
import {useState} from "react";
import { useEffect } from "react";

const ActiveCampaignForm = (props) => {
    const[loadForm, setLoadForm] =useState(false)
    useEffect(()=>{
        if (props.form === false) {
            setLoadForm(true);
        } else {
            setLoadForm(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    
    return (
        <div className="formContainer" id="form">
            <div className="_form_1">
                
                {loadForm === true ? (
                    <HelmetProvider>
                        <Helmet>
                            <script
                                src="https://pltfrm.activehosted.com/f/embed.php?id=1"
                                type="text/javascript"
                                charset="utf-8"
                            ></script>
                        </Helmet>
                    </HelmetProvider>
                ) : (
                    <>
                    </>
                )}
            </div>
        </div>
    );
};

export default ActiveCampaignForm;
