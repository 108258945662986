import image1 from "../assets/pltfrmImage1.png";
import image2 from "../assets/pltfrmImage2.png";
import image3 from "../assets/pltfrmImage3.png";

const DiscoverContent=()=>{

    return (
        <>
            <div className="flexContainer">
                <div className="imageContainer imageContainer1">
                    <img
                        src={image1}
                        alt="Male personal trainer wearing a PLTFRM T-shirt and holding a clipboard and pen in is hands"
                    ></img>
                    <div className="textContainer">
                        <h4>1. Sign Up</h4>
                        <p>to become a PLTFRM PRO</p>
                    </div>
                </div>
                <div className="imageContainer imageContainer2">
                    <img
                        src={image2}
                        alt="Female personal trainer wearing a PLTFRM T-shirt and holding a plank pose"
                    ></img>
                    <div className="textContainer">
                        <h4>2. Book</h4>
                        <p>a time and space to train your clients</p>
                    </div>
                </div>
                <div className="imageContainer imageContainer3">
                    <img
                        src={image3}
                        alt="Three personal trainers standing together and smiling"
                    ></img>
                    <div className="textContainer">
                        <h4>3. Be the Boss</h4>
                        <p>of your business and your charges</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DiscoverContent