import blogImage1 from "../assets/blogAssets/blogPost5-img1.png";
import blogImage2 from "../assets/blogAssets/blogPost15-img2.png";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ShareButtons from "../components/ShareButtons";

const BlogContent15 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/how-to-make-the-most-out-of-your-working-hours"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>
                    "A Flexible Workday: How to make the most out of your
                    working hours."
                </title>

                <meta
                    name="description"
                    content="Anyone who thinks the life of an entrepreneur is ‘easy’ has obviously never been one. In this post, we take you through the pros and cons of being able to set your own schedule. We also share some ways you can make the most of the freedom you have as an entrepreneur. This is an article for all our trainers who are ready to get to work growing their businesses."
                />
            </Helmet>
            <h2 ref={elementRef}>A Flexible Workday</h2>
            <h3 className="subTitleH3">
                How to make the most out of your working hours
            </h3>
            <div className="authorContainer">
                <h4>April 13th, 2023</h4>
                <p>by PLTFRM</p>
            </div>
            <img
                className="blogImg"
                src={blogImage1}
                alt="PLTFRM reception and gym space in Toronto"
            />
            <p>
                Let’s be honest, one of the most alluring parts of running your
                own business is the flexibility to set your own schedule and
                hours.
            </p>
            <p>
                Now keeping honesty in mind, let’s also admit that as exciting
                as setting your own schedule can seem there are also some
                downsides to it.
            </p>
            <p>
                When you are running your own business and set your own schedule
                you need to be disciplined.
            </p>
            <p>
                Discipline is one of the most important characteristics of a
                successful entrepreneur.
            </p>
            <p>
                As a fitness professional, you already know how important
                discipline is when it comes to reaching any fitness goal. You
                need to show up, and you need to show up consistently.
            </p>

            <p>Now take that same mentality and apply it to your business.</p>

            <p>
                When you have the flexibility to do anything and everything with
                your day…
            </p>

            <p>
                … you need to be disciplined in order to make the most of that
                flexibility.
            </p>
            <p>
                As a full-time personal trainer running your own business, there
                are probably many different things you need to be doing.
            </p>

            <div className="specialList">
                <p>You have</p>

                <ul className="blogList">
                    <li className="listStyle1">
                        <p>1:1 clients</p>
                    </li>
                    <li>
                        <p>Group classes that you teach</p>
                    </li>
                    <li>
                        <p>Daily admin work</p>
                    </li>
                    <li>
                        <p>
                            The content you need to create for your marketing
                            efforts.
                        </p>
                    </li>
                    <li>
                        <p>
                            Maybe some brand deals or influencer work you are
                            doing on the side
                        </p>
                    </li>
                </ul>
            </div>

            <p>
                Your day and your business consist of a lot of different moving
                parts.
            </p>

            <p>
                This is where PLTFRM comes in. We are a gym specifically
                designed for personal trainers to run their business out of.
            </p>

            <img
                className="blogImgType2"
                src={blogImage2}
                alt="Infographic listing the 7 facilities PLTFRM provides to personal trainers"
            />

            <p>PLTFRM is a gym for personal trainers.</p>

            <p>
                Imagine having a space you can do everything you need for your
                business in one place.
            </p>

            <p>
                Our downtown, King West location puts us in the heart of the
                actions.
            </p>

            <p>
                You will have a private gym space to train your clients in. They
                can come to you. You no longer need to spend your time running
                around.
            </p>

            <p>
                As we said, when you run your own business learning how to
                organize your day to get the most out of it is very important.
            </p>
            <p>
                We understand that entrepreneurs are forever doing a million
                plus one things. The way you get everything done is by
                organizing yourself and your day.
            </p>

            <p>
                Anyone who thinks the life of an entrepreneur is ‘easy’ has
                obviously never been one.
            </p>

            <h3 className="blogH3">
                Here is what’s included when you sign on to become a PLTFRMPRO
            </h3>

            <ul className="blogList">
                <li>
                    <p>
                        Right now you will be added to our roaster of trainers
                        to be a part of our upcoming launch
                    </p>
                </li>
                <li>
                    <p>You get exclusive access to our space when it’s ready</p>
                </li>
                <li>
                    <p>
                        You also get exclusive access to our introductory rates
                    </p>
                </li>
                <li>
                    <p>You are not tied to PLTFRM in any way.</p>
                </li>
                <li>
                    <p>
                        You get to run your business on your own. You will get
                        priority access to our community and book schedule.
                    </p>
                </li>
            </ul>

            <button className="blogButton">
                <Link to="/discover">Become a pltfrmPro</Link>
            </button>

            <h3 className="blogH3">What do you think?</h3>

            <p>
                We would love to hear your thoughts on what you need as a
                personal trainer to grow your business.
            </p>

            <p>We are here to serve first and always.</p>

            <p>
                Contact our marketing team at{" "}
                <a href="mailto: marketing@pltfrm.fit">marketing@pltfrm.fit</a>{" "}
                with any questions or inquiries.
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/the-two-communities-every-personal-trainer-must-build">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/we-know-you-are-doing-this-for-more-than-just-the-money">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent15;
