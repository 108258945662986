import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost21-img1.png";
import blogImage2 from "../assets/blogAssets/blogPost21-img2.png";

const BlogContent21 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/the-new-age-of-personal-training"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>
                    The New Age of Personal Training: And it goes way beyond
                    fitness
                </title>

                <meta
                    name="description"
                    content="If you are struggling to stay in the box of what you’ve been told a personal trainer should do, this article is for you. The way personal trainers are being viewed is changing and it’s changing fast. The possibilities of how you can run a business and share your love for fitness are countless. Let’s dive into these changes and talk about what they mean for you and your fitness business."
                />
            </Helmet>
            <h2 ref={elementRef}>The New Age of Personal Training</h2>
            <h3 className="subTitleH3">And it goes way beyond fitness</h3>
            <div className="authorContainer">
                <h4>May 26, 2023</h4>
                <p>by PLTFRM</p>
            </div>
            <img
                className="blogImg"
                src={blogImage}
                alt="5 PLTFRM trainers standing with their arms crossed in t-shirts with the PLTFRM logo"
            />
            <p>
                Why do people hire personal trainers and what are they looking
                for when they hire one?
            </p>
            <p>
                The traditional reasons are simple, people want someone
                knowledgeable on fitness who can help them get fit. People hire
                someone because at the end of the day they are too tired to have
                to figure out a working routine themselves. They hire someone
                who can help them be accountable. They hire someone believing
                that this person will help them reach their performance, weight,
                or aesthetic goals.
            </p>
            <p>People hire personal trainers for help getting fit.</p>
            <p>Is that all?</p>
            <p>
                With the rise of social media as a tool of business for personal
                trainers the reason why personal trainers are being hired has
                started shift.
            </p>
            <img
                className="blogImg"
                src={blogImage2}
                alt="graphics of stereotypically fit people."
            />
            <p>
                Personal trainers are no longer only viewed as these big or
                extra lean humans that have perfect bodies who will help us do
                the same.
            </p>
            <p>
                When we see a personal trainer on social media who has built a
                successful brand for themselves we probably see someone whose{" "}
                <strong>influence</strong> goes way past fitness.
            </p>
            <p>
                When someone builds a personal brand they are sharing a part of
                themselves and their story on social media and that is what
                people connect with.
            </p>
            <p>
                People are looking for trainers to be{" "}
                <strong>multifaceted</strong> now. They want to see and connect
                with the human behind the personal trainer and that is often who
                they end up hiring.
            </p>
            <p>What this means is the job of a personal trainer has changed.</p>
            <p>
                Yes, people want you to answer their fitness questions, but they
                also want more.
            </p>
            <p>
                They want answers to their <strong>deeper questions</strong>{" "}
                about sleep, lifestyles, how you balance work with other
                priorities in life.
            </p>
            <p>
                Think about it, you a see a trainer on Instagram with a big
                following talking about the struggles of running a business and
                getting 8 hours of sleep every night because she knows that’s
                important. Someone hear’s that story and things click for them.
                They decide to work with her not just to get fit but because
                they believe she could help them with their sleep because she’s
                struggled with it herself. <strong>She understands</strong>.
                They’ll be able to talk to her.
            </p>
            <p>What does this change mean for you as a trainer?</p>
            <p>
                Well, to start you need to change the way you view yourself. You
                need to realise that your influence and brand goes beyond just
                the fitness realm.
            </p>
            <p>
                This could mean that you need to focus on educating yourself
                past just fitness as well. It means, now more than ever, you
                need to make sure you know what you are talking about and
                sharing.
            </p>
            <p>
                You get to be more than just a trainer. Don’t be afraid to share
                the parts of you that go beyond fitness because these are the
                important parts. These are the parts that people are going to
                connect with.
            </p>
            <ShareButtons url={location.pathname} />
            
            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/are-there-too-many-personal-trainers">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/exceptional-customer-service">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent21;
