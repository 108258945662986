
import NavBar from "../components/NavBar";
import "../styling/BlogPost.scss";

import { Outlet } from "react-router-dom";

import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import DiscoverContent from "../components/DiscoverContent";
import ActiveCampaignFormPopup from "../components/ActiveCampaignFormPopup";

const BlogPost =()=>{

    const location = useLocation();
    const elementRef = useRef(null);
    const [signUp, setSignUp] = useState(false);

    useEffect(
        function () {
            if (location.pathname === "/BlogPost") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
            setSignUp(false);
        },
        [location]
    );

    const handleClick = () => {
        setSignUp(!signUp);
    };

    return (
        <>
            <div className="wrapper">
                <div className="blogPost">
                    <NavBar />

                    <section className="blogText" ref={elementRef}>
                        <Outlet />
                    </section>
                </div>
            </div>

            <section className="signUpToday signUpTodayBlog">
                <div className="wrapper">
                    <h2 className="fitnessSectionTitle">
                        Become a
                        <span className="importantHeader"> PLTFRMPRO</span>{" "}
                        today
                    </h2>
                    <p>
                        Sign up for our email list and join an exclusive
                        community of personal trainers in Toronto. You will get
                        access to our private slack channel to network and build
                        connections. You will also get exclusive access to
                        PLTFRM offers and news.
                    </p>
                    <button onClick={handleClick}>Sign up</button>
                </div>
            </section>

            <section>
                {signUp ? <ActiveCampaignFormPopup signUp={signUp} /> : <></>}
            </section>

            <section className="discoverSection" id="discover">
                <div className="wrapper">
                    <div className="discoverContent">
                        <div className="signUpText">
                            <h3>NOW OPEN</h3>
                            <p>
                                All memberships are currently 50% off the first
                                3 months
                            </p>
                            <button>
                                <Link to="/memberships/membershipOptions">
                                    Our Memberships
                                </Link>
                            </button>
                        </div>
                        <DiscoverContent />
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogPost