import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ShareButtons from "../components/ShareButtons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

import blogImage from "../assets/blogAssets/blogPost18-img1.png";

const BlogContent18 = () => {
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (
                location.pathname ===
                "/blog/blogPost/a-conversation-with-KD-about-life-and-basketball"
            ) {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        },
        [location]
    );

    return (
        <section className="specialBlog">
            <Helmet>
                <title>A Conversation with KD about Life and Basketball</title>

                <meta
                    name="description"
                    content="Join us this week for a conversation with KD, aka @ballsnbells. KD is building a career he loves that began from a childhood dream. He sat down with our team to share his struggles, beliefs, and wisdom. This is a great article for anyone at the start of their career trying to find some direction."
                />
            </Helmet>

            <h2 ref={elementRef}>
                A Conversation with KD about Life and Basketball
            </h2>

            <div className="authorContainer">
                <h4>May 9, 2023</h4>
                <p>by PLTFRM</p>
            </div>

            <img
                className="blogImg"
                src={blogImage}
                alt="Featuring trainer KD with kettlebells and text “featuring @ballsnbells” with a PLTFRM logo"
            />

            <p>
                We had the pleasure of speaking with Kamaldip Gill, aka KD or{" "}
                <a href="https://www.instagram.com/ballsnbells/">
                    @ballsnbells
                </a>{" "}
                on Instagram recently. KD is a personal trainer that focuses on
                kettlebell work and basketball training. KD has a positive
                infectious energy that shines through when you speak to him.
            </p>

            <p>
                KD’s fitness journey started in high school when he fell in love
                with basketball and daydreamed about being in the NBA. “My older
                brother played basketball and was a big sneakerhead and that’s
                how I was introduced to basketball.” By high school, KD knew a
                career in basketball was not for him but that didn’t mean he
                loved the sport any less.KD shared how he realized the NBA was
                not in his future but knew that he wanted to help others get
                better at basketball and strength training. “My fitness journey
                started with basketball but later moved to a focus on
                athleticism and that’s what I do today too.”
            </p>

            <p className="blogQuoteText">
                <span className="tab"></span>
                “My fitness journey started with basketball but later moved to a
                focus on athleticism and that’s what I do today too.”
            </p>
            <p>
                KD shared how as he got older he started going to the gym and
                lifting but that quickly got monotonous and started to feel
                boring. He went on to share that “this was when I started
                getting into kettlebell training. There is a whole culture
                around kettlebell training. It took me about a year to perfect
                my style”.
            </p>
            <p>
                Today, KD’s personal training focuses on kettlebell work and
                basketball training. KD spoke about how “basketball was my first
                love and I still play as much as I can today. It’s my favorite
                form of cardio. I get my cardio from a sport because I find it
                the most engaging.”
            </p>
            <p>
                We went on to talk about the lessons KD learned in basketball
                that he has taken into life and he shared that the big lesson
                for him was the importance of consistency. “There is no way you
                make any progress if you do things just once or twice a week.
                You need to keep showing up whether it's for a career or your
                fitness. If you are not consistent you are going to keep feeling
                like you are always playing catch up. If you can be consistent
                you are no longer going to feel like you are always behind.”
            </p>
            <p className="blogQuoteText">
                <span className="tab"></span>
                “If you are not consistent you are going to keep feeling like
                you are always playing catch up.”
            </p>
            <p>
                KD shared with our team that he has been working on his
                consistency for so long now that he longer needs to rely on
                routine to get him to show up for his practice. KD said, “It’s
                more of a routine now. Even on the days when I don’t feel like
                working, I’ll still do a little something small because it’s a
                part of the day I look forward to.”
            </p>
            <p>
                Our conversation with KD was enlightening and so positive. KD
                started his career with a love for a sport in middle school and
                has held to some of that energy in his career today. He is a
                beautiful example of someone who is willing to explore where
                life takes him and is fearless in his pursuit to love the work
                he does.
            </p>
            <p>
                You can connect with KD on Instagram{" "}
                <a href="https://www.instagram.com/ballsnbells/">
                    @ballsnbells
                </a>
                . We are so excited to watch him grow as he continues to shoot
                for the stars!
            </p>

            <ShareButtons url={location.pathname} />

            <div className="blogArrowContainer">
                <div className="blogArrowNext blogArrow">
                    <Link to="/blog/blogPost/Emily-Dugan-on-women-and-fitness">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">next blog post</span>
                        <p>next blog post</p>
                    </Link>
                </div>

                <div className="blogArrowLast blogArrow">
                    <Link to="/blog/blogPost/the-business-side-of-client-acquisition">
                        <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="blogArrowIcon"
                        />
                        <span className="sr-only">previous blog post</span>
                        <p>previous blog post</p>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default BlogContent18;
